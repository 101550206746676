<template>
  	<section class="topheader-section">
	    <div class="row">
      		<div class="col-md-5"></div>
      		<div class="col-md-7">
        		<header class="topheader align-items-center">
          			<div class="left-logo">
            			<img src="../assets/images/logo.png" />
          			</div>
          			<div class="right-sign">
            			<a href="#" v-on:click="signinLink()">Sign In</a>
          			</div>
        		</header>
      		</div>
    	</div>
  	</section>
  	<section class="ragister-login loginpage">
	    <div class="row d-flex align-items-center">
      		<div class="col-md-5 left-box">
        		<div class="inner-slider">
          			<div id="sliderlogin" class="carousel slide" data-bs-ride="false" data-interval="false">
            			<div class="carousel-indicators">
              				<button type="button" data-bs-target="#sliderlogin" data-bs-slide-to="0" class="active"></button>
              				<button type="button" data-bs-target="#sliderlogin" data-bs-slide-to="1" ></button>
              				<button type="button" data-bs-target="#sliderlogin" data-bs-slide-to="2" ></button>
            			</div>
            			<div class="carousel-inner">
              				<div class="carousel-item active">
                				<h3>We Help Startup And Digital Agencies Complete Projects</h3>
              				</div>
              				<div class="carousel-item">
                				<h3>We Help Startup And Digital Agencies Complete Projects</h3>
              				</div>
              				<div class="carousel-item">
                				<h3>We Help Startup And Digital Agencies Complete Projects</h3>
              				</div>
            			</div>
          			</div>
        		</div>
      		</div>
      		<div class="col-md-7 right-box">
    			<div class="form-body">
          			<h1>Forgot your Password?</h1>
          			<form class="form-box" @submit.prevent>
            			<input type="email" name="email" v-model="formData.email" placeholder="Enter email address" :class="[errors.email ? 'invalid-class' : '']" required />
            			<p style="color: red">{{ errors.title }}</p>
            			<div class="logindiv">
            	  			<input type="submit" value="Submit" @click="forgotPassword" />
            			</div>
          			</form>
        		</div>
      		</div>
    	</div>
  	</section>
  	<section class="bottomcopyright">
	    <div class="row">
      		<div class="col-md-5">
        		<p>© Copyright 2021 Sanatan Tech Innovations Pvt Ltd, All Right Reserved</p>
      		</div>
    	</div>
  	</section>
</template>

<script>
import axios from "axios";
import base_url from "../base_url.js";
export default {
  	data() {
	    return {
      		formData: {
        		email: "",
      		},
      		errors: [],
    	};
  	},
  	created() {
	    document.title = "Forgot Password";
  	},
  	methods: {
    	async forgotPassword() {
      		this.errors = [];
      		if (!this.formData.email) {
        		this.errors["title"] = "Email is Required";
      		} else {
        		await axios.post(`${base_url}/forgot-password`, {
            		email: this.formData.email,
          		}).then((res) => {
            		if (res.data == "Invalid Email Address.") {
              			this.errors["title"] = res.data;
            		} else {
              			localStorage.setItem("mail_token", res.data[1]);
						this.errors["title"] = "Please Check Your Email Address We send Forgot Password Link.";
              			// setInterval(() => {
              			//   this.$router.push("/login");
              			// }, 10000);
            		}
          		});
      		}
    	},
    	signinLink() {
      		this.$router.push("/login");
    	},
  	},
};
</script>
