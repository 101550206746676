<template>
    <section class="topheader-section">
        <div class="row">
            <div class="col-md-5"></div>
            <div class="col-md-7">
                <header class="topheader align-items-center">
                    <div class="left-logo">
                        <img src="../assets/images/logo_new.png" />
                    </div>
                    <div class="right-sign">
                        <a v-on:click="signupLink()">Sign up</a>
                    </div>
                </header>
            </div>
        </div>
    </section>
    <section class="ragister-login loginpage">
        <div class="row d-flex align-items-center">
            <div class="col-md-5 left-box">
                <div class="inner-slider">
                    <div id="sliderlogin" class="carousel slide" data-bs-ride="false" data-interval="false">
                        <div class="carousel-indicators">
                            <button type="button" data-bs-target="#sliderlogin" data-bs-slide-to="0" class="active"></button>
                            <!-- <button
                              type="button"
                              data-bs-target="#sliderlogin"
                              data-bs-slide-to="1"
                            ></button>
                            <button
                              type="button"
                              data-bs-target="#sliderlogin"
                              data-bs-slide-to="2"
                            ></button> -->
                        </div>
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <h3>Empower your Team with Time Tracking and many other features</h3>
                            </div>
                            <!-- <div class="carousel-item">
                              <h3>Empower your Team with Time Tracking and many other features</h3>
                            </div>
                            <div class="carousel-item">
                              <h3>Empower your Team with Time Tracking and many other features</h3>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-7 right-box">
                <div class="form-body">
                    <h1>Log in to your account</h1>
                    <form class="form-box" @submit.prevent>
                        <p style="color: red">{{ errors.title }}</p>
                        <input type="email" name="email" placeholder="Email Address*" v-model="formData.email" :class="[errors.email ? 'invalid-class' : '']" required />
                        <p style="color: red">{{ errors.email }}</p>
                        <input type="password" name="pass" placeholder="Password*" v-model="formData.password" required />
                        <p style="color: red">{{ errors.pass }}</p>
                        <div class="logindiv">
                            <input type="submit" value="Login And Continue" @click="login_user" style="padding: 15px 20px; width: 41%; text-align: center" />
                            <span>or</span>
                            <div class="google-div">
                                <a @click="handleSignIn"><img src="../assets/images/google.png" />Sign in with Google</a>
                            </div>
                        </div>
                    </form>
                    <div class="col-12">
                        <div class="forgot-pass-link">
                            <a><label style="cursor: pointer" @click="forgotPassword">Forgot Password?</label></a>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="create-new-link">
                            New user?<router-link to="register">Create New Account</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="bottomcopyright">
        <div class="row">
            <div class="col-md-5">
                <p>© Copyright 2023 Menejar, All Right Reserved</p>
            </div>
        </div>
    </section>
</template>

<script>
import logo from "../images/logo 1.png";
import axios from "axios";
import base_url from "../base_url.js";
import { inject } from "vue";

export default {
    setup() {
        const Vue3GoogleOauth = inject("Vue3GoogleOauth");
        return {
            Vue3GoogleOauth,
        };
    },
    data() {
        return {
            formData: {
                email: "",
                password: "",
            },
            errors: [],
            logo: logo,
            user: "",
        };
    },
    beforeCreate() {
        document.title = "Login Page";
        if (localStorage.getItem("type") == "staff") {
            this.$router.push({ name: "staff_dashboard" });
        } else if (localStorage.getItem("type") == "admin") {
            this.$router.push({ name: "dashboard" });
        } else if (localStorage.getItem("type") == "client") {
            this.$router.push({ name: "client_projects" });
        }
    },
    methods: {
        async handleSignIn() {
            const googleUser = await this.$gAuth.signIn();
            if (!googleUser) {
                return null;
            } else {
                await axios.post(`${base_url}/googlelogin`, {
                    email: googleUser.getBasicProfile().getEmail(),
                }).then((res) => {
                    if (res.data.token !== "0") {
                        localStorage.setItem("type", res.data.user["admin"]);
                        localStorage.setItem("token", res.data.token);
                        localStorage.setItem("access", res.data.access["type"]);
                        localStorage.setItem("permission", res.data.permission["details"]["permission"]);
                    }
                    this.errors["title"] = res.data.title;
                    if (res.data.title == "Login Success") {
                        if (res.data.user["admin"] == "staff") {
                            this.$router.push({ name: "staff_dashboard" });
                        } else if (res.data.user["admin"] == "admin") {
                            this.$router.push({ name: "dashboard" });
                        } else {
                            this.$router.push({ name: "client_projects" });
                        }
                    }
                });
            }
        },
        async login_user(e) {
            e.preventDefault();
            if (!this.formData.email) {
                this.errors["email"] = "Email is Required";
            }
            if (!this.formData.password) {
                this.errors["pass"] = "Password is Required";
            }

            if (this.formData.email && this.formData.password) {
                await axios.post(`${base_url}/login`, {
                    email: this.formData.email,
                    password: this.formData.password,
                }).then((res) => {
                    if (res.data.token !== "0") {
                        localStorage.setItem("type", res.data.user["admin"]);
                        localStorage.setItem("token", res.data.token);
                        localStorage.setItem("access", res.data.access["type"]);
                        localStorage.setItem("permission", res.data.permission["details"]["permission"]);
                    }
                   
                    this.errors["title"] = res.data.title;
                    if (res.data.title == "Login Success") {
                        if (res.data.user["admin"] == "staff") {
                            this.$router.push({ name: "staff_dashboard" });
                        } else if (res.data.user["admin"] == "admin") {
                            this.$router.push({ name: "dashboard" });
                        } else {
                            this.$router.push({ name: "client_projects" });
                        }
                    }
                });
            }
        },
        signupLink() {
            this.$router.push("/register");
        },  
        forgotPassword() {
            this.$router.push({ name: "forgotpassword" });
        },
    },
};
</script>
