<template>
  <div class="panelbox">
    <LeftPannel
      :image="this.$store.state.user.image"
      :cname="this.$store.state.user.cname"
    />
    <div class="right-panel">
      <div class="header">
        <CommonHeader
          type="admin"
          title="Timesheet"
          :name="this.$store.state.user.name"
        />
      </div>
      <div class="content-page admin-attendance-page">
        <div class="fullrow">
          <div
            class="track-top-filter-form common-white-shadow-background admin-attendance-filter"
          >
            <div class="row">
              <div class="col-2">
                <select v-model="this.selectedStaff">
                  <option value="" selected>Select Staff</option>
                  <option v-for="data in names" :value="data.id" :key="data.id">
                    {{ data.name == "" ? ( data.firstname + " " + data.lastname ) : data.name}}
                  </option>
                </select>
              </div>
              <div class="col-4">
                <select
                  name="Select_Month"
                  id="Select-Month"
                  v-model="currentMonth"
                  @change="getmonthsweek($event.target.value)"
                >
                  <option value="" selected>Select Month</option>
                  <option
                    v-for="data in listMonths"
                    :value="data.val"
                    :key="data.name"
                  >
                    {{ data.name }}
                  </option>
                </select>
              </div>
              <div class="col-4">
                <select v-model="this.currentweek" @change="getWeeksDay()">
                  <option value="" selected>Select Week</option>
                  <option
                    v-for="data in weekarray"
                    :value="data.value"
                    :key="data.name"
                  >
                    {{ data.name }}
                  </option>
                </select>
              </div>
              <div class="col-2">
                <input
                  type="submit"
                  value="Get Timesheet"
                  class="yellow-button"
                  @click="getStaffTimesheet"
                />
              </div>
              <!-- <input
                type="submit"
                value="Clear"
                class="yellow-button"
                v-if="filter.is_filter == 1"
                @click="removeSearch"
              /> -->
            </div>
          </div>
        </div>

        <div>
          <div v-if="filter.is_filter == 1">
            Showing {{ this.result_length }} on {{ this.pages.length }} page
          </div>
          <div class="col-md-12 page-dropdown">
            <select
              class="select-dropdown"
              v-model="pageSize"
              @change="pageChange($event)"
            >
              <option value="50">50</option>
              <option value="30">30</option>
              <option value="20">20</option>
              <option value="10">10</option>
            </select>
          </div>
          <div class="col-12">
            <div class="admininvoice-pagination">
              <div v-if="filter.is_filter == 0" class="pagination">
                <a v-if="page != 1" @click="prevPage">
                  <img
                    src="../../src/assets/images/pagi-left.png"
                    alt="&laquo;"
                  />
                </a>
                <a
                  :class="pageNumber == page ? 'active' : ''"
                  v-for="pageNumber in pages.slice(page - 1, page + 2)"
                  :key="pageNumber"
                  @click="pageCount(pageNumber)"
                >
                  {{ pageNumber }}
                </a>
                <a @click="nextPage" v-if="page < pages.length">
                  <img
                    src="../../src/assets/images/pagi-right.png"
                    alt="&raquo;"
                  />
                </a>
              </div>
              <div v-else class="pagination">
                <a v-if="page != 1" @click="prevPages">
                  <img
                    src="../../src/assets/images/pagi-left.png"
                    alt="&laquo;"
                  />
                </a>
                <a
                  :class="pageNumber == page ? 'active' : ''"
                  v-for="pageNumber in pages.slice(page - 1, page + 2)"
                  :key="pageNumber"
                  @click="pageCounts(pageNumber)"
                >
                  {{ pageNumber }}
                </a>
                <a @click="nextPages" v-if="page < pages.length">
                  <img
                    src="../../src/assets/images/pagi-right.png"
                    alt="&raquo;"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="table-box fullwidth-table staff-dashboard-table-body">
                <table>
                  <tr>
                    <th>Project</th>
                    <th v-for="data in this.weekday" :key="data.index">
                      {{
                        new Date(
                          this.year + "-" + (this.months + 1) + "-" + data
                        ).toLocaleDateString("en-us", { weekday: "short" })
                      }}
                      <br />{{ data }}/{{ this.months + 1 }}
                    </th>
                    <th>Total Hrs</th>
                  </tr>
                  <tr v-for="(timesheet, k) in this.timesheetData1" :key="k">
                    <td>
                      <input
                        v-if="timesheet.project_id != ''"
                        type="text"
                        :value="timesheet.project_name"
                      />
                    </td>
                    <td v-for="(data, i) in timesheet.project_ts_data" :key="i">
                      <input type="text" v-model="data.time" />
                    </td>
                    <td>
                      <input
                        v-if="timesheet.total_hours != 0"
                        type="text"
                        v-model="timesheet.total_hours"
                      />
                    </td>
                  </tr>
                </table>
              </div>
              <div>
                <div class="col-2">
                  <input
                    type="submit"
                    value="Approve"
                    class="yellow-button"
                    @click="approveTimesheet"
                  />
                </div>
              </div>
              <!-- Pagination -->
              <div class="col-12">
                <div class="admininvoice-pagination">
                  <div v-if="filter.is_filter == 0" class="pagination">
                    <a v-if="page != 1" @click="prevPage"
                      ><img
                        src="../../src/assets/images/pagi-left.png"
                        alt="&laquo;"
                    /></a>
                    <a
                      :class="pageNumber == page ? 'active' : ''"
                      v-for="pageNumber in pages.slice(page - 1, page + 2)"
                      :key="pageNumber"
                      @click="pageCount(pageNumber)"
                    >
                      {{ pageNumber }}
                    </a>
                    <a @click="nextPage" v-if="page < pages.length">
                      <img
                        src="../../src/assets/images/pagi-right.png"
                        alt="&raquo;"
                      />
                    </a>
                  </div>
                  <div v-else class="pagination">
                    <a v-if="page != 1" @click="prevPages"
                      ><img
                        src="../../src/assets/images/pagi-left.png"
                        alt="&laquo;"
                    /></a>
                    <a
                      :class="pageNumber == page ? 'active' : ''"
                      v-for="pageNumber in pages.slice(page - 1, page + 2)"
                      :key="pageNumber"
                      @click="pageCounts(pageNumber)"
                    >
                      {{ pageNumber }}
                    </a>
                    <a @click="nextPages" v-if="page < pages.length"
                      ><img
                        src="../../src/assets/images/pagi-right.png"
                        alt="&raquo;"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CommonFooter />
    </div>
  </div>
</template>

<script>
import LeftPannel from "../../src/components/admin/LeftPannel.vue";
import CommonHeader from "../../src/components/admin/CommonHeader.vue";
import CommonFooter from "../../src/components/CommonFooter.vue";
import axios from "axios";
import base_url from "../../src/base_url";

const $ = require("jquery");
window.$ = $;

export default {
  components: {
    LeftPannel,
    CommonHeader,
    CommonFooter,
  },
  data() {
    return {
      errors: [],
      type: "",
      items: [],
      names: [],
      page: 1,
      filter_page: 1,
      perPage: 50,
      pageSize: 50,
      selectedStaff: "",
      pages: [],
      today: new Date().toISOString().substr(0, 10),
      filter: {
        is_filter: 0,
        start_date: new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          new Date().getDate() + 1
        )
          .toISOString()
          .substr(0, 10),
        end_date: new Date().toISOString().substr(0, 10),
      },
      //new variabe above are remove after complete working this page
      listMonths: [
        {
          val: new Date().getFullYear() + "-1",
          name: "January - " + new Date().getFullYear(),
        },
        {
          val: new Date().getFullYear() + "-2",
          name: "February - " + new Date().getFullYear(),
        },
        {
          val: new Date().getFullYear() + "-3",
          name: "March - " + new Date().getFullYear(),
        },
        {
          val: new Date().getFullYear() + "-4",
          name: "April -" + new Date().getFullYear(),
        },
        {
          val: new Date().getFullYear() + "-5",
          name: "May - " + new Date().getFullYear(),
        },
        {
          val: new Date().getFullYear() + "-6",
          name: "June - " + new Date().getFullYear(),
        },
        {
          val: new Date().getFullYear() + "-7",
          name: "July - " + new Date().getFullYear(),
        },
        {
          val: new Date().getFullYear() + "-8",
          name: "August - " + new Date().getFullYear(),
        },
        {
          val: new Date().getFullYear() + "-9",
          name: "September - " + new Date().getFullYear(),
        },
        {
          val: new Date().getFullYear() + "-10",
          name: "October - " + new Date().getFullYear(),
        },
        {
          val: new Date().getFullYear() + "-11",
          name: "November - " + new Date().getFullYear(),
        },
        {
          val: new Date().getFullYear() + "-12",
          name: "December - " + new Date().getFullYear(),
        },
      ],
      currentMonth:
        new Date().getFullYear() + "-" + (new Date().getMonth() + 1),
      weekarray: [],
      months: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      weekday: [],
      year: new Date().getFullYear(),
      currentweek: "",
      timesheetData1: [
        {
          project_id: "",
          project_ts_data: [],
          total_hours: 0,
        },
      ],
      // timesheetData: [],
    };
  },
  created() {
    this.type = localStorage.getItem("type");
    axios
      .post(`${base_url}/user`, {
        headers: { token: localStorage.getItem("token") },
        type: localStorage.getItem("type"),
      })
      .then((res) => {
        this.$store.state.user = res.data.user;
        this.getName();
        if (localStorage.getItem("AttendanceFilter")) {
          var staffid = JSON.parse(localStorage.getItem("AttendanceFilter"))[
            "staffid"
          ];
          var indate = JSON.parse(localStorage.getItem("AttendanceFilter"))[
            "indate"
          ];
          var outdate = JSON.parse(localStorage.getItem("AttendanceFilter"))[
            "outdate"
          ];
          this.filter.staffid = staffid;
          this.filter.indate = indate;
          this.filter.outdate = outdate;
          this.selected = false;
          this.filterSearch();
        } else {
          this.getName();
        }
      });
  },
  watch: {
    items() {
      this.setPages();
    },
  },
  mounted() {
    this.monthsList(this.currentYear, this.months);
  },
  methods: {
    //list of all months week date
    async monthsList(year, month) {
      const weeks = [];
      const firstDate = new Date(year, month, 1);
      const lastDate = new Date(year, month + 1, 0);
      const numDays = lastDate.getDate();
      const date = new Date().getDate();
      let dayOfWeekCounter = firstDate.getDay();

      for (let date = 1; date <= numDays; date++) {
        if (dayOfWeekCounter === 0 || weeks.length === 0) {
          weeks.push([]);
        }
        weeks[weeks.length - 1].push(date);
        dayOfWeekCounter = (dayOfWeekCounter + 1) % 7;
      }

      var fullmonth = firstDate.toLocaleString("default", { month: "long" });
      month = month + 1;
      this.weeks = weeks;
      this.weekarray = weeks.filter((w) => !!w.length).map((w) => ({
        start: w[0],
          end: w[w.length - 1],
          value: year + "-" + month  + "-" + `${w[0]<10?`0${w[0]}`:w[0]}` + ":" +  year + "-" + month + "-"+ `${w[w.length - 1] < 10 ? `0${w[w.length - 1]}` : w[w.length - 1]}`,
          name: `${w[0] < 10 ? `0${w[0]}` : w[0]}` + "-" + fullmonth +  "-" + year + " : " +  `${ w[w.length - 1] < 10 ? `0${w[w.length - 1]}` : w[w.length - 1] }` + "-" + fullmonth + "-" + year,
          dates: w,
        }));


      weeks.filter((w) => { if(w.includes(date)) {  this.currentweek = year + "-" + month  + "-" + `${w[0] < 10 ? `0${w[0]}` : w[0]}` + ":" +  year + "-" + month + "-"+ `${w[w.length - 1] < 10 ? `0${w[w.length - 1]}` : w[w.length - 1]}`; this.weekday =w;}  });
    },

    //display week based on months name
    async getmonthsweek(e) {
      var month = e.substr(e.indexOf("-") + 1);
      this.selectMonth, (this.months = parseInt(month) - 1);
      //  = parseInt(month);
      this.year = this.currentYear;

      this.monthsList(this.currentYear, parseInt(month) - 1);
      // this.timesheetData = null;
    },

    //display week day based on selected week
    async getWeeksDay() {
      // this.timesheetData = null;
      if (this.timesheetData1.length > 1) {
        this.timesheetData = [
          {
            project_id: "",
            project_ts_data: [],
            total_hours: 0,
          },
        ];
      }
      var first_date = this.currentweek.substr(
        0,
        this.currentweek.indexOf(":")
      );
      var last_date = this.currentweek.substr(
        this.currentweek.indexOf(":") + 1
      );
      var first_reverse = first_date.split("-").reverse().join("-");
      var last_reverse = last_date.split("-").reverse().join("-");

      var week_first = first_reverse.substr(0, first_reverse.indexOf("-"));
      var week_last = last_reverse.substr(0, last_reverse.indexOf("-"));

      this.weeks.filter((w) => {
        if (week_first == w[0] && week_last == w[w.length - 1]) {
          this.weekday = w;
        }
      });
    },

    //get sheet for timesheet
    async getStaffTimesheet() {
      let staff_id = this.selectedStaff;
      let selMonth = this.currentMonth;
      let selWeek = this.currentweek;
      if (this.timesheetData1.length > 1) {
        this.timesheetData1 = [
          {
            project_id: "",
            project_ts_data: [],
            total_hours: "",
          },
        ];
      }
      this.timesheetData1.pop();

      if (staff_id == "") {
        this.$notify({
          title: "Please select staff first",
          type: "warn",
        });
      } else {
        try {
          const response = await axios.post(`${base_url}/getstafftimesheet`, {
            staffid: staff_id,
            month: selMonth,
            week: selWeek,
          });

          //new
          let results = response.data;

          results.map((result) => {
              this.timesheetData1.push({
                project_id: result.sheet_data.project_id,
                project_name: result.project_name,
                project_ts_data: result.sheet_data.project_ts_data,
                total_hours: result.sheet_data.total_hours,
              });
          });
          this.selected = true;
        } catch (error) {
          console.log(error);
        }

      }
    },

    //Approve timesheet
    async approveTimesheet() {
      const month = this.currentMonth;
      const week = this.currentweek;
      const staff_id = this.selectedStaff;
      if (this.selected) {
        try {
          await axios.post(`${base_url}/approvetimesheet`, {
            month: month,
            week: week,
            staffid: staff_id,
          });
        } catch (error) {
          console.log(error);
        }
        this.$notify({
          title: "Timesheet Approved",
          type: "success",
        });
      } else {
        this.$notify({
          title: "Please Load Timesheet",
          type: "warn",
        });
      }
    },

    async filterSearch() {
      var filter = {
        staffid: this.filter.staffid,
        indate: this.filter.indate,
        outdate: this.filter.outdate,
      };
      localStorage.setItem("AttendanceFilter", [JSON.stringify(filter)]);
      const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
      await axios
        .post(`${base_url}/attendance_search` + params, {
          admin_id: this.$store.state.user.id,
          staffid: this.filter.staffid,
          indate: this.filter.indate,
          outdate: this.filter.outdate,
        })
        .then((res) => {
          this.items = res.data.results;
          this.page = res.data.page;
          this.result_length = res.data.length.length;
          this.filter.is_filter = 1;
        });
    },
    async removeSearch() {
      this.filter_page = 1;
      this.filter.staffid = "";
      (this.filter.indate = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        new Date().getDate() + 1
      )
        .toISOString()
        .substr(0, 10)),
        (this.filter.outdate = new Date().toISOString().substr(0, 10));
      const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
      await axios
        .post(`${base_url}/attendance_search` + params, {
          admin_id: this.$store.state.user.id,
          staffid: this.filter.staffid,
          indate: this.filter.indate,
          outdate: this.filter.outdate,
        })
        .then((res) => {
          localStorage.removeItem("AttendanceFilter");
          this.items = res.data.results;
          this.page = res.data.page;
          this.result_length = res.data.length.length;
          this.filter_page = 1;
          this.filter.is_filter = 0;
        });
    },
    async getName() {
      const response = await axios.post(
        `${base_url}/staffsfortrack/${this.$store.state.user.id}`
      );
      this.names = response.data;
    },
    convertDate: function (cvt_date) {
      var date = new Date(cvt_date);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var dt = date.getDate();
      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }
      const cvt_date_formate = dt + "/" + month + "/" + year;
      return cvt_date_formate;
    },
    setPages() {
      this.pages = [];
      this.perPage = this.pageSize;
      let numberOfPages = Math.ceil(this.result_length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    //page number wise display
    pageCount(pageNumber) {
      $(window).scrollTop(0);
      if (!pageNumber) {
        this.page++;
      }
      this.page = pageNumber;
      this.showAttendance();
    },
    //filter page number
    pageCounts(pageNumber) {
      if (!pageNumber) {
        this.filter_page++;
      }
      this.filter_page = pageNumber;
      this.filterSearch();
    },
    //display prev page
    prevPage() {
      $(window).scrollTop(0);
      this.page--;
      this.showAttendance();
    },
    //filter prev page
    prevPages() {
      this.filter_page--;
      this.filterSearch();
    },
    //display next page
    nextPage() {
      $(window).scrollTop(0);
      this.page++;
      this.showAttendance();
    },
    nextPages() {
      this.filter_page++;
      this.filterSearch();
    },
    //per page change
    pageChange() {
      this.filter_page = 1;
      this.page = 1;
      if (this.filter.is_filter == 1) {
        this.filterSearch();
      } else {
        this.showAttendance();
      }
    },
  },
};
</script>

<style></style>
