<template>
  <div class="panelbox">
    <LeftPannel :image="this.$store.state.user.image" :cname="this.$store.state.user.cname" />
    <div :class="type == 'admin' ? 'right-panel' : 'right-panel staff-section-page'
      ">
      <div class="header">
        <CommonHeader v-if="type == 'admin'" title="Projects" :name="this.$store.state.user.name" />
        <staff-header v-else title="Projects"></staff-header>
      </div>
      <div class="loaderbox" v-if="this.projects.length < 0">
        <img style="width: 100px" src="../assets/images/Loading-bar.gif" alt="&laquo;" />
      </div>
      <div class="content-page admin-projects-page">
        <div class="fullrow">
          <div class="row admin-project-toppart">
            <div class="col-md-4">
              <input type="text" class="search-leave satff-listing-page-search" placeholder="Search Projects"
                v-model="filter.name" />
            </div>
            <div class="col-md-4">
              <input type="submit" value="Submit" class="yellow-button" @click="filterSearch" />
              <input type="submit" value="Clear" v-if="filter.is_filter == 1" class="yellow-button"
                @click="removeSearch" />
            </div>
            <div class="col-md-4">
              <div class="addnew-button">
                <router-link :to="{ name: 'create_project' }"><span>+</span>Add New</router-link>
              </div>
            </div>
            <div v-if="filter.is_filter == 1">
              Showing {{ this.result_length }} on {{ this.pages.length }} page
            </div>
            <div class="col-md-12 page-dropdown">
              <select class="select-dropdown" v-model="pageSize" @change="pageChange($event)">
                <option value="50">50</option>
                <option value="30">30</option>
                <option value="20">20</option>
                <option value="10">10</option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="fullwidth-table track-table-body admin-project-table-body">
                <table>
                  <tr>
                    <th style="width: 5%">ID</th>
                    <th style="width: 18%">Project Name</th>
                    <th>Short Description</th>
                    <th>Client Information</th>
                    <th style="width: 20%">Actions</th>
                    <th>Activaion</th>
                  </tr>
                  <tr v-for="(project, index) in projects" :key="project.id">
                    <td>{{ index + 1 }}</td>
                    <td>{{ project.project_name }}</td>
                    <td>
                      <div v-if="project.description.length <= 50">
                        {{ project.description }}
                      </div>
                      <div v-else>
                        {{ project.description.substring(0, 50) + ".." }}
                      </div>
                    </td>
                    <td>
                      <ul>
                        <li>{{ project.client_name }}</li>
                        <li>
                          <img src="../assets/images/envelope.png" alt="Email" />{{ project.email }}
                        </li>
                        <li v-if="project.phone">
                          <img src="../assets/images/telephone.png" alt="Phone" />{{ project.phone }}
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul>
                        <li>
                          <router-link :to="{
      name: 'view_project',
      params: { id: project.id },
    }">
                            <img src="../assets/images/visibility.png" alt="Delete" />
                          </router-link>
                        </li>
                        <li>
                          <router-link :to="{
      name: 'edit_project',
      params: { id: project.id },
    }">
                            <img src="../assets/images/pen.png" alt="Edit" />
                          </router-link>
                        </li>
                        <li>
                          <a @click="deleteProjects(project.id)"><img src="../assets/images/close.png"
                              alt="Delete" /></a>
                        </li>
                      </ul>
                    </td>
                    <td style="display: flex; justify-content: center;">
                      <div :class="project.is_active == 0
                      ? 'toggle-switch checked'
                      : 'toggle-switch'
                      " @click="toggleActiveStatus(project)">
                        <div class="toggle-handle"></div>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="col-12">
              <div class="admininvoice-pagination">
                <div v-if="filter.is_filter == 0" class="pagination">
                  <a v-if="page != 1" @click="prevPage"><img src="../assets/images/pagi-left.png" alt="&laquo;" /></a>
                  <a :class="pageNumber == page ? 'active' : ''" v-for="pageNumber in pages.slice(page - 1, page + 2)"
                    :key="pageNumber" @click="pageCount(pageNumber)">
                    {{ pageNumber }}
                  </a>
                  <a @click="nextPage" v-if="page < pages.length">
                    <img src="../assets/images/pagi-right.png" alt="&raquo;" />
                  </a>
                </div>
                <div v-else class="pagination">
                  <a v-if="page != 1" @click="prevPages">
                    <img src="../assets/images/pagi-left.png" alt="&laquo;" /></a>
                  <a :class="pageNumber == page ? 'active' : ''" v-for="pageNumber in pages.slice(page - 1, page + 2)"
                    :key="pageNumber" @click="pageCounts(pageNumber)">
                    {{ pageNumber }}
                  </a>
                  <a @click="nextPages" v-if="page < pages.length">
                    <img src="../assets/images/pagi-right.png" alt="&raquo;" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CommonFooter />
    </div>
  </div>
</template>
<script>
import axios from "axios";
import LeftPannel from "../components/admin/LeftPannel.vue";
import CommonHeader from "../components/admin/CommonHeader.vue";
import CommonFooter from "../components/CommonFooter.vue";
import StaffHeader from "../components/user/CommonHeader.vue";
import base_url from "../base_url.js";
const $ = require("jquery");
window.$ = $;
export default {
  components: {
    LeftPannel,
    CommonHeader,
    CommonFooter,
    StaffHeader,
  },
  data() {
    return {
      type: "",
      projects: [],
      page: 1,
      filter_page: 1,
      result_length: "",
      perPage: 50,
      pageSize: 50,
      pages: [],
      filter: {
        name: "",
        is_filter: 0,
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("type") == "staff") {
      if (localStorage.getItem("permission").includes("projects")) {
        next();
      } else {
        next({ name: "staff_dashboard" });
      }
    } else {
      next();
    }
  },
  created() {
    this.type = localStorage.getItem("type");

    axios
      .post(`${base_url}/user`, {
        headers: { token: localStorage.getItem("token") },
        type: localStorage.getItem("type"),
      })
      .then((res) => {
        this.$store.state.user = res.data.user;
        if (localStorage.getItem("ProjectsFilter")) {
          var name = JSON.parse(localStorage.getItem("ProjectsFilter"))["name"];
          this.filter.name = name;
          this.filterSearch();
        } else {
          this.getProjects();
        }
        if (this.$store.state.user.is_business == 1) {
          this.$router.push("/projects");
        } else {
          this.$router.push("/business_setting");
        }
      });
  },
  watch: {
    projects() {
      this.setPages();
    },
  },
  methods: {
    async filterSearch() {
      var filter = {
        name: this.filter.name,
      };
      localStorage.setItem("ProjectsFilter", [JSON.stringify(filter)]);
      const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
      await axios
        .post(`${base_url}/project_search` + params, {
          name: this.filter.name,
          admin_id: this.$store.state.user.id,
        })
        .then((res) => {
          this.projects = res.data.results;
          this.page = res.data.page;
          this.result_length = res.data.length.length;
          this.filter.is_filter = 1;
        });
    },
    async removeSearch() {
      this.filter_page = 1;
      this.filter.name = "";
      const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
      await axios
        .post(`${base_url}/project_search` + params, {
          name: this.filter.name,
          admin_id: this.$store.state.user.id,
        })
        .then((res) => {
          localStorage.removeItem("ProjectsFilter");
          this.projects = res.data.results;
          this.page = res.data.page;
          this.result_length = res.data.length.length;
          this.filter_page = 1;
          this.filter.is_filter = 0;
        });
    },


    //per page change
    pageChange() {
      this.filter_page = 1;
      this.page = 1;
      if (this.filter.is_filter == 1) {
        this.filterSearch();
      } else {
        this.getProjects();
      }
    },
    async getProjects() {
      const params = "?pages=" + this.page + "&size=" + this.pageSize;
      const response = await axios.post(`${base_url}/get_projects` + params, {
        admin_id: this.$store.state.user.id,
      });
      console.log(response.data);
      this.projects = response.data.results;
      this.page = response.data.page;
      this.result_length = response.data.length.length;
    },
    async toggleActiveStatus(project) {
      console.log(project);
      project.is_active = project.is_active === 0 ? 1 : 0;
      const data = {
        status: project.is_active
      };
      await axios.put(`${base_url}/handle_project_status/${project.id}`, data);
      this.getProjects();
      this.$notify({
        title: "<b>Project</b> status updated successfully!",
        type: "warn",
      });



    },
    async deleteProjects(id) {
      await axios.post(`${base_url}/remove_projects/${id}`).then(() => { });
      this.getProjects();
      this.$notify({
        title: "<b>Project</b> deleted successfully!",
        type: "warn",
      });
    },
    setPages() {
      this.pages = [];
      this.perPage = this.pageSize;
      let numberOfPages = Math.ceil(this.result_length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    //page number wise display
    pageCount(pageNumber) {
      $(window).scrollTop(0);
      if (!pageNumber) {
        this.page++;
      }
      this.page = pageNumber;
      this.getProjects();
    },
    //filter page number
    pageCounts(pageNumber) {
      if (!pageNumber) {
        this.filter_page++;
      }
      this.filter_page = pageNumber;
      this.filterSearch();
    },
    //display prev page
    prevPage() {
      $(window).scrollTop(0);
      this.page--;
      this.getProjects();
    },
    //filter prev page
    prevPages() {
      this.filter_page--;
      this.filterSearch();
    },
    //display next page
    nextPage() {
      $(window).scrollTop(0);
      this.page++;
      this.getProjects();
    },
    nextPages() {
      this.filter_page++;
      this.filterSearch();
    },
  },
};
</script>
