<template>
    <section class="header-section">
        <div class="header-section-setup">
          	<div class="container bigger">
            	<div class="row wrap_content">
              		<div class="col-sm-6 head-left">
               			<div class="head-logo"><img src="../assets/images/logo_new.png"></div>
              		</div>
              		<div class="col-sm-6 head-right text-right">
                		<div class="right-sign-up-btn login-reg common-btn"><a v-on:click="loginLink()">Login</a></div>
                		<div class="right-sign-up-btn login-reg dark-bg-btn common-btn"><a v-on:click="signupLink()">Sign up</a></div>
              		</div>
             	</div>
          	</div>
        </div>
        <div class="main-title-setup">
          	<div class="container">
            	<div class="row">
                	<h1 class="text-center">Empower your Team with Time Tracking and many other features</h1>
                	<div class="main-title-btm-btn common-btn"><a v-on:click="signupLink()">Try it for free now</a></div>
            	</div>
            </div>
        </div>
    </section>
    <section class="body-section">
        <div class="container bigger">
        	<div class="big-blue-section">
          		<div class="blue-title">
            		<h2>Best time tracking features for your business,<br>
              		creating the building blocks for success.</h2>
              		<p>Track productivity, attendance, Project Management, Time off, and Invoice with a simple time tracker and timesheet..</p>
          		</div>
        	</div>
        </div>
        <div class="blue-img-setup">
          	<div class="container">
	            <div class="row">
              		<img src="../assets/images/DashBoard1.jpg">
            	</div>
          	</div>
        </div>
  
        <!-- why menejar section -->
        <div class="why-use-menejar-section">
          	<div class="container">
            	<div class="row">
              		<h2 class="text-center">Why Use a Menejar?</h2>
              		<p class="text-center">Menejar streamlines your team work so you can focus on what matters</p>
            	</div>
            	<div class="reasons-list">
              		<div class="row">
                		<div class="col-sm-4 single-reasons text-center">
                  			<img src="../assets/images/new_eye.png">
                  			<h3>Boost productivity</h3>
                  			<div class="reason-desc">Track time you spend on activities, see where your time goes, and improve your time management skills.</div>
                		</div>
                		<div class="col-sm-4 single-reasons text-center">
                  			<img src="../assets/images/checked.png">
                  			<h3>Streamline processes</h3>
                  			<div class="reason-desc">Track time and analyze your company's efficiency across projects, clients, departments, and employees.</div>
                		</div>
                		<div class="col-sm-4 single-reasons text-center">
                  			<img src="../assets/images/teamwork.png">
                  			<h3>Manage team</h3>
                  			<div class="reason-desc">Track attendance for payroll and accounting, see who works on what, and manage workload among teams.</div>
                		</div>  
              		</div>
            	</div>
          	</div>
        </div>
  
        <!-- choose right menejar section -->
        <div class="choose-right-mng-sec">
          	<div class="container">
	            <div class="row">
              		<div class="col-sm-6 choose-left">
                		<img src="../assets/images/time_tracking.png">
              		</div>
              		<div class="col-sm-6 choose-right">
                		<h2>Time Tracking</h2>
                		<p>You can easily track time on tasks and projects, create time entries, capture billable hours, and save time on paperwork when it comes to generating time reports. Dive into a detailed overview of the time utilization and precisely evaluate productivity.</p>
              		</div>
            	</div>
          	</div>
        </div>
  
        <!-- ease of use section -->
        <div class="ease-of-use-sec">
          	<div class="container">
	            <div class="row">
              		<div class="col-sm-6 ease-of-left">
                		<h2>Project Management</h2>
                		<p>Create projects, set rates and budgets. Keep tracking of hours spent on tasks and see the project progress at a glance, collaborate easily, keep deadlines and prevent over budgeting</p>
              		</div>
              		<div class="col-sm-6 ease-of-right">
                		<img src="../assets/images/Project_Management.png">
              		</div>					
            	</div>
          	</div>
        </div>
  
        <!-- choose right menejar section -->
        <div class="choose-right-mng-sec">
            <div class="container">
                <div class="row">
                  	<div class="col-sm-6 choose-left">
                    	<img src="../assets/images/Times-off-leave.png">
                  	</div>
                  	<div class="col-sm-6 choose-right">
	                    <h2>Times off management</h2>
                    	<p>Menejar is a free time tracker with additional features that lets you request personal time off, and track leaves and absences.</p>
                  	</div>
                </div>
            </div>
        </div>
  
        <!-- ease of use section -->
        <div class="ease-of-use-sec">
          	<div class="container">
            	<div class="row">
              		<div class="col-sm-6 ease-of-left">
                		<h2>Reporting</h2>
                		<p>Menejar time reporting tool helps you gain all important data of your business running in one place. Peer into its progress with just one click.</p>
              		</div>
              		<div class="col-sm-6 ease-of-right">
                		<img src="../assets/images/Reporting.png">
              		</div>  
            	</div>
          	</div>
        </div>
  
        <!-- time tracking section -->
        <div class="time-tracking-sec">
          	<div class="container">
            	<div class="row">
              		<div class="col-sm-6 time-tracking-left">
                		<img src="../assets/images/invoice.png">
              		</div>
              		<div class="col-sm-6 time-tracking-right">
                		<h2>Invoicing</h2>
                  		<p>With the accurate billing system, set billable rates and easily create an invoice based on tracked time and expenses, and get paid promptly.</p>
              		</div>                
            	</div>
          	</div>
        </div>
    </section>
    <section class="footer-section">
        <div class="testimonial-section">
          	<div class="container bigger">
            	<div class="">
              		<div class="testimonial-list-setup row">
                		<div  class="col-sm-1"><img src="../assets/images/left-quote.png"></div>
                		<div class="testi-data-content col-sm-10">
                  			<p class="testi-data">It make our life easy. We track developer time and time off. It help us to keep understand spend time and keep project within budget and also in future budget.</p>
                  			<span class="testi-name text-center"><strong>-Eric</strong></span>
                		</div>
                		<div  class="col-sm-1"><img src="../assets/images/right-quote.png"></div>
              		</div>
            	</div>
          	</div>
        </div>
    </section>
    <section class="footer-bottom-section">
        <div class="container bigger">
          	<div class="row">
            	<div class="col-sm-6 footer-btm-left">
               		<div class="foot-logo"><img src="../assets/images/logo_new.png"></div>
               		<div class="copyright"><p>© Copyright 2023 Menejar, All rights reserved.</p></div>
            	</div>
            	<div class="col-sm-6 footer-btm-right">
	                <div class="signup-desc">
                  		<p>Sign up and get started with menejar today.</p>
                  		<p> A world of productive teamwork awaits!</p>
                	</div>
                	<div class="signup-form">
                  		<form action="">
                    		<div class="form-filed">
                      			<input type="email" name="email" placeholder="Email">
                    		</div>
                    		<div class="form-filed">
                      			<input type="submit" name="submit" value="Sign up">
                    		</div>							
                  		</form>
                	</div>
            	</div>
          	</div>
        </div>
    </section>
</template>

<script>
import logo from "../images/logo 1.png";
import axios from "axios";
import base_url from "../base_url.js";
import { inject } from "vue";

export default {
  	setup() {
    	const Vue3GoogleOauth = inject("Vue3GoogleOauth");
    	return {
      		Vue3GoogleOauth,
    	};
  	},
  	data() {
    	return {
	      	formData: {
        		email: "",
        		password: "",
      		},
      		errors: [],
      		logo: logo,
      		user: "",
    	};
  	},
  	beforeCreate() {
    	document.title = "Login Page";
    	if (localStorage.getItem("type") == "staff") {
      		this.$router.push({ name: "staff_dashboard" });
    	} else if (localStorage.getItem("type") == "admin") {
      		this.$router.push({ name: "dashboard" });
    	} else {
      		this.$router.push({ name: "client_projects" });
    	}
  	},
  	methods: {
	    async handleSignIn() {
      		const googleUser = await this.$gAuth.signIn();
      		if (!googleUser) {
        		return null;
      		} else {
        		await axios.post(`${base_url}/googlelogin`, {
            		email: googleUser.getBasicProfile().getEmail(),
          		}).then((res) => {
            		if (res.data.token !== "0") {
              			localStorage.setItem("type", res.data.user["admin"]);
              			localStorage.setItem("token", res.data.token);
              			localStorage.setItem("access", res.data.access["type"]);
              			localStorage.setItem("permission", res.data.permission["details"]["permission"]);
            		}
            		this.errors["title"] = res.data.title;
            		if (res.data.title == "Login Success") {
              			if (res.data.user["admin"] == "staff") {
                			this.$router.push({ name: "staff_dashboard" });
              			} else if (res.data.user["admin"] == "admin") {
                			this.$router.push({ name: "dashboard" });
              			} else {
                			this.$router.push({ name: "client_projects" });
              			}
            		}
          		});
      		}
    	},
    	async login_user(e) {
      		e.preventDefault();
      		if (!this.formData.email) {
        		this.errors["email"] = "Email is Required";
      		}
      		if (!this.formData.password) {
        		this.errors["pass"] = "Password is Required";
      		}

      		if (this.formData.email && this.formData.password) {
        		await axios.post(`${base_url}/login`, {
            		email: this.formData.email,
            		password: this.formData.password,
          		}).then((res) => {
            		if (res.data.token !== "0") {
              			localStorage.setItem("type", res.data.user["admin"]);
              			localStorage.setItem("token", res.data.token);
              			localStorage.setItem("access", res.data.access["type"]);
              			localStorage.setItem("permission", res.data.permission["details"]["permission"]);
            		}
            		this.errors["title"] = res.data.title;
            		if (res.data.title == "Login Success") {
              			if (res.data.user["admin"] == "staff") {
                			this.$router.push({ name: "staff_dashboard" });
              			} else if (res.data.user["admin"] == "admin") {
                			this.$router.push({ name: "dashboard" });
              			} else {
                			this.$router.push({ name: "client_projects" });
              			}
            		}
          		});
      		}
    	},
    	signupLink() {
      		this.$router.push("/register");
    	},
    	loginLink() {
      		this.$router.push("/login");
    	}
  	},
};
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');
</style>
<style>
    @import '../assets/css/custom.css';
   
</style>
<!-- <style>
    @import '../assets/css/bootstrap.min.css';
</style> -->