<template>
	<footer class="footer">
	  <p>© Copyright {{ currentYear }} Sanatan Tech Innovations Pvt Ltd, All Right Reserved</p>
	</footer>
  </template>
  
  <script>
  export default {
	data() {
	  return {
		currentYear: new Date().getFullYear() // Get the current year
	  };
	}
  };
  </script>
  
  <style>
  /* Add your CSS styles here */
  .footer {
	text-align: center;
	padding: 1em;
	background-color: #f1f1f1;
  }
  </style>
  