<template>
    <div class="attendance-report">
      <div class="panelbox">
        <LeftPannel
          :image="userImage"
          :cname="userCname"
        />
        <div
          :class="panelClass"
        >
          <div class="header">
            <CommonHeader
              v-if="isAdmin"
              title="Reports"
              :name="userName"
            />
            <StaffHeader v-else title="Attendance Report"></StaffHeader>
          </div>
          <div class="content-page">
            <router-link to="/attendance_report">
              1. Attendance Report
            </router-link>
            <p>
              See if/when an employee was Present/Absent/On Leave/Weekly off, etc. for selected dates.
            </p>
            <router-link to="/invoice_report">
                2. Invoice Report
              </router-link>
              <p>
                See invoice status and received amount.
              </p>
          </div>
         
          <CommonFooter />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import LeftPannel from "../components/admin/LeftPannel.vue";
  import CommonHeader from "../components/admin/CommonHeader.vue";
  import CommonFooter from "../components/CommonFooter.vue";
  import StaffHeader from "../components/user/CommonHeader.vue";
  
  export default {
    components: {
      LeftPannel,
      CommonHeader,
      CommonFooter,
      StaffHeader,
    },
    data() {
      return {
        type: localStorage.getItem("type") || 'staff', 
      };
    },
    computed: {
      isAdmin() {
        return this.type === 'admin';
      },
      panelClass() {
        return this.isAdmin ? 'right-panel' : 'right-panel staff-section-page';
      },
      userImage() {
        return this.$store.state.user?.image || ''; 
      },
      userCname() {
        return this.$store.state.user?.cname || ''; 
      },
      userName() {
        return this.$store.state.user?.name || 'User'; 
      }
    }
  };
  </script>
  
  <style>
  /* Add any necessary styling here */
  </style>
  