<template>
  <div class="panelbox">
    <LeftPannel
      :image="this.$store.state.user.image"
      :cname="this.$store.state.user.cname"
    />
    <div
      :class="
        type == 'admin' ? 'right-panel' : 'right-panel staff-section-page'
      "
    >
      <div class="header">
        <CommonHeader
          v-if="type == 'admin'"
          title="Invoices"
          :name="this.$store.state.user.name"
        />
        <staff-header v-else title="Invoices"></staff-header>
      </div>
      <div class="content-page">
        <div class="fullrow">
          <div class="row">
            <div class="col-md-4 invoice-head-search-part">
              <input
                type="text"
                class="search-leave invoice-page"
                placeholder="Search Invoices"
                style="width: 374px"
                v-model="filter.name"
              />
            </div>
            <div class="col-md-4">
              <input
                type="submit"
                value="Submit"
                class="yellow-button"
                @click="filterSearch"
              />
              <input
                type="submit"
                value="Clear"
                class="yellow-button"
                style="width: auto; margin-left: 5px"
                v-if="filter.is_filter == 1"
                @click="removeSearch"
              />
            </div>
            <div class="col-md-4 invoice-head-addnew-part">
              <div class="addnew-button">
                <router-link :to="{ name: 'create_invoice' }"
                  ><span>+</span>Add New</router-link
                >
              </div>
            </div>
            <div class="col-12">
              <div
                class="table-box fullwidth-table invoice-filter admin-invoice-page"
              >
                <table style="background: #1e4077">
                  <tr>
                    <th>
                      <label for="selectall">
                        <input
                          id="selectall"
                          type="checkbox"
                          style="width: 15px; height: 15px"
                          @click="checkAll"
                          v-model="formData.isCheckAll"
                        />Select All
                      </label>
                      <ul>
                        <li
                          style="margin: 5px"
                          v-for="data in invoice_cat"
                          :value="data.id"
                          :key="data.name"
                        >
                          <button
                            :class="
                              data.name == 'Paid'
                                ? 'gray-btn approve ainvoice-paid'
                                : data.name == 'Unpaid'
                                ? 'gray-btn reject ainvoice-unpaid'
                                : data.name == 'Archive'
                                ? 'gray-btn approve ainvoice-paid'
                                : 'gray-btn reject ainvoice-unpaid'
                            "
                            @click="invoiceUnpaid(data.id)"
                          >
                            {{ data.name }}
                          </button>
                        </li>
                      </ul>
                    </th>
                    <th>
                      <select
                        class="right-dropdown"
                        style="margin: 10px 5px 5px 10px"
                        name="issort"
                        v-model="formData.isSort"
                        @change="sortChange($event)"
                      >
                        <option value="" selected>Sort results by</option>
                        <option
                          v-for="data in invoice_sort"
                          :value="data.val"
                          :key="data.name"
                        >
                          {{ data.name }}
                        </option>
                      </select>
                    </th>
                    <th>
                      <select
                        class="right-dropdown"
                        name="iscat"
                        v-model="formData.isCat"
                        @change="catChange($event)"
                      >
                        <option value="" selected>Invoice Category</option>
                        <option
                          v-for="data in invoice_cat"
                          :value="data.id"
                          :key="data.name"
                        >
                          {{ data.name }}
                        </option>
                      </select>
                    </th>
                  </tr>
                </table>
                <table class="threecolumn" style="background: #1e4077">
                  <tr>
                    <th>
                      <input
                        class="right-dropdown"
                        type="date"
                        name="from"
                        id="From"
                        v-model="filter.fromdate"
                        @change="searchDate($event)"
                      />
                    </th>
                    <th>
                      <input
                        class="right-dropdown"
                        type="date"
                        name="to"
                        id="To"
                        v-model="filter.duedate"
                        @change="searchDate($event)"
                      />
                    </th>
                    <th>
                      <select
                        class="right-dropdown"
                        name="issort"
                        v-model="selectedClient"
                        @change="searchClient($event)"
                      >
                        <option value="" selected>Client Name</option>
                        <option
                          v-for="data in filter.clients"
                          :value="data.id"
                          :key="data.id"
                        >
                          {{ data.organization }}
                          {{ data.name }}
                        </option>
                      </select>
                    </th>
                  </tr>
                </table>
              </div>
            </div>
            <div class="col-12">
              <div v-if="filter.is_filter == 1">
                Showing {{ this.result_length }} on {{ pages.length }} page
              </div>
            </div>
            <div class="col-md-12 page-dropdown">
              <select
                class="select-dropdown"
                v-model="pageSize"
                @change="pageChange($event)"
              >
                <option value="50">50</option>
                <option value="30">30</option>
                <option value="20">20</option>
                <option value="10">10</option>
              </select>
            </div>
            <div class="loaderbox" v-if="this.loading">
              <img
                style="width: 100px"
                src="../assets/images/Loading-bar.gif"
                alt="&laquo;"
              />
            </div>
            <div class="col-12">
              <div class="fullwidth-table admin-invoice-page-body">
                <table>
                  <tr>
                    <th></th>
                    <th style="text-align: left">Client</th>
                    <th>From Date</th>
                    <th style="text-align: center; width: 11%">Due Date</th>
                    <th>Amount</th>
                    <th>Received Amount</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                  <tr v-for="data in this.invoices" :key="data.id">
                    <td>
                      <input
                        type="checkbox"
                        :value="data.id"
                        @change="updateCheckall()"
                        v-model="formData.checked"
                      />
                    </td>
                    <td style="text-align: center">
                      {{ data.organization }} {{ data.name }}
                    </td>
                    <td style="text-align: center">
                      <div>{{ convertDate(data.date) }}</div>
                    </td>
                    <td style="text-align: center">
                      <div>{{ convertDate(data.due_date) }}</div>
                    </td>
                    <td style="text-align: center; width: 20%">
                      <strong>{{ data.total }} {{ data.currency }}</strong
                      ><br />
                      <div v-if="data.is_paid == 0">
                        <div
                          v-if="dateDifference(this.date, data.due_date) >= 0"
                        >
                          Become Due in
                          {{ dateDifference(this.date, data.due_date) }} Days
                        </div>
                        <div v-else>
                          Due for
                          {{
                            Math.abs(dateDifference(this.date, data.due_date))
                          }}
                          Days
                        </div>
                      </div>
                     
                    </td>
                    <td style="text-align: center; width: 20%">
                      <strong> {{ data.received_ammount==="0"?"-":data.received_ammount }}
                      {{
                        data.received_ammount_currency
                          ? data.received_ammount_currency
                          : ""
                      }}</strong><br />
                       <div  v-if="data.is_paid == 1">
                        Paid on {{ convertDate(data.updated_at) }}
                      </div>
                      
                    </td>
                    <td style="text-align: center">
                      <div v-if="data.is_paid == 0">
                        <div style="text-align: center" class="gray-btn reject">
                          Due
                        </div>
                      </div>
                      <div
                        v-else-if="data.is_paid == 1"
                        style="text-align: center"
                        class="gray-btn approve"
                      >
                        Paid
                      </div>
                      <div
                        v-else-if="data.is_paid == 2"
                        class="gray-btn approve"
                      >
                        Archive
                      </div>
                      <div v-else class="gray-btn reject">Cancel</div>
                    </td>
                    <td style="padding-right: 0">
                      <ul style="display: flex; padding: 0">
                        <li>
                          <router-link
                            style="width: 20px !important"
                            :to="{
                              name: 'edit_invoice',
                              params: { id: data.id },
                            }"
                          >
                            <img
                              style="cursor: pointer"
                              src="../assets/images/pen.png"
                              alt="&laquo;"
                            />
                          </router-link>
                        </li>
                        <li>
                          <router-link
                            style="width: 50px !important"
                            :to="{
                              name: 'downloadpdfpage',
                              params: { id: data.id },
                            }"
                          >
                            <img
                              style="cursor: pointer"
                              src="../assets/images/download-arrow.png"
                              alt="&laquo;"
                            />
                          </router-link>
                        </li>
                        <li>
                          <a
                            @click="deleteInvoice(data.id)"
                            style="width: 20px !important; margin-right: 5px"
                          >
                            <img
                              src="../assets/images/delete.png"
                              alt="Delete"
                            />
                          </a>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="col-12">
              <div class="admininvoice-pagination">
                <div class="pagination">
                  <a v-if="page != 1" @click="prevPage"
                    ><img src="../assets/images/pagi-left.png" alt="&laquo;"
                  /></a>
                  <a
                    :class="pageNumber == page ? 'active' : ''"
                    v-for="pageNumber in pages.slice(page - 1, page + 2)"
                    :key="pageNumber"
                    @click="pageCount(pageNumber)"
                  >
                    {{ pageNumber }}
                  </a>
                  <a @click="nextPage" v-if="page < pages.length">
                    <img src="../assets/images/pagi-right.png" alt="&raquo;" />
                  </a>
                </div>
              </div>
            </div>
            <p></p>
            <div class="col-md-12 page-dropdown">
              <select
                class="select-dropdown"
                v-model="filter.currency"
                @change="filterCurrency($event)"
              >
                <option value="" selected>Currency</option>
                <option
                  v-for="data in filter.currencise"
                  :value="data.code"
                  :key="data.code"
                >
                  {{ data.code }}
                </option>
              </select>
            </div>
            <div class="col-12" style="display: flex; gap: 10%">
              <div class="track-total-time">
                <ul>
                  <li>Total</li>
                  <li>{{ this.filter.totalCurrency }}</li>
                </ul>
              </div>
              <div class="track-total-time">
                <ul>
                  <li>Total Paid</li>
                  <li>{{ this.filter.totalPaid }}</li>
                </ul>
              </div>
              <div class="track-total-time">
                <ul>
                  <li>Total Unpaid</li>
                  <li>{{ this.filter.totalUnpaid }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CommonFooter />
    </div>
  </div>
</template>
<script>
import LeftPannel from "../components/admin/LeftPannel.vue";
import CommonHeader from "../components/admin/CommonHeader.vue";
import CommonFooter from "../components/CommonFooter.vue";
import StaffHeader from "../components/user/CommonHeader.vue";
import axios from "axios";
import base_url from "../base_url.js";
const $ = require("jquery");
window.$ = $;

export default {
  components: {
    LeftPannel,
    CommonHeader,
    CommonFooter,
    StaffHeader,
  },
  data() {
    return {
      loading: true,
      type: "",
      invoices: [],
      page: 1,
      perPage: 50,
      pageSize: 50,
      filter_page: 1,
      result_length: "",
      pages: [],
      date: new Date().toISOString().substr(0, 10),
      selectedClient: "",
      formData: {
        checked: [],
        isCat: "",
        isSort: "",
        isCheckAll: "false",
      },
      filter: {
        name: "",
        is_filter: 0,
        fromdate: "",
        duedate: "",
        clients: "",
        currency: "",
        currencise: [],
        received_ammount_currency: "",
        received_ammount: "",
        totalCurrency: "",
        totalPaid: "",
        totalUnpaid: "",
      },
      is_sort: 0,
      is_list: 0,
      is_cat: 0,
      is_search: 0,
      is_date: 0,
      is_client: 0,
      invoice_cat: [
        { id: 2, name: "Archive" },
        { id: 3, name: "Cancel" },
        { id: 1, name: "Paid" },
        { id: 0, name: "Unpaid" },
      ],
      invoice_sort: [
        { val: "amt_desc", name: "By Amount Desc" },
        { val: "dt_desc", name: "By Date Desc" },
        { val: "amt_asc", name: "By Amount Asc" },
        { val: "dt_asc", name: "By Date Asc" },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("type") == "staff") {
      if (localStorage.getItem("permission").includes("invoice")) {
        next();
      } else {
        next({ name: "staff_dashboard" });
      }
    } else {
      next();
    }
  },
  created() {
    this.type = localStorage.getItem("type");
    axios
      .post(`${base_url}/user`, {
        headers: { token: localStorage.getItem("token") },
        type: localStorage.getItem("type"),
      })
      .then((res) => {
        this.$store.state.user = res.data.user;
        this.filterClient(), this.getCurrency();
        if (localStorage.getItem("InvoicesFilter")) {
          var name = JSON.parse(localStorage.getItem("InvoicesFilter"))["name"];
          this.filter.name = name;
          this.filterSearch();
        } else {
          // this.getInvoice();
          this.getAllFilterInvoice();
        }
        this.formData.isCat = "";
        this.formData.isSort = "";
        if (this.$store.state.user.is_business == 1) {
          this.$router.push("/invoices");
        } else {
          this.$router.push("/business_setting");
        }
      });
  },
  watch: {
    invoices() {
      this.setPages();
    },
  },
  methods: {
    async getInvoice() {
      this.is_list = 1;
      const params = "?pages=" + this.page + "&size=" + this.pageSize;
      axios
        .get(`${base_url}/get_inovoice/${this.$store.state.user.id}` + params)
        .then((res) => {
          this.loading = false;
          this.invoices = res.data.results;
          this.page = res.data.page;
          this.result_length = res.data.length.length;
        });
    },
    async getAllFilterInvoice() {
      this.is_list = 1;
      const params = "?pages=" + this.page + "&size=" + this.pageSize;
      axios
        .post(
          `${base_url}/get_all_filter_inovoice/${this.$store.state.user.id}` +
            params,
          {
            sort_by: this.formData.isSort,
            cat_id: this.formData.isCat,
            client_id: this.selectedClient,
            start_date: this.filter.fromdate,
            due_date: this.filter.duedate,
            received_ammount_currency: this.filter.received_ammount_currency,
            received_ammount: this.filter.received_ammount,
            admin_id: this.$store.state.user.id,
          }
        )
        .then((res) => {
          console.log(res.data.results, "invoice res");
          this.loading = false;
          console.log(res);
          this.invoices = res.data.results;
          this.page = res.data.page;
          this.result_length = res.data.length.length;
        });
    },
    async getCurrency() {
      const response = await axios.get(`${base_url}/currency`);
      this.filter.currencise = response.data;
    },
    async filterClient() {
      const response = await axios.post(`${base_url}/filterclients`, {
        admin_id: this.$store.state.user.id,
      });
      this.filter.clients = response.data;
    },
    setPages() {
      this.pages = [];
      this.perPage = this.pageSize;
      let numberOfPages = Math.ceil(this.result_length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    dateDifference(date, due_date) {
      const date_1 = new Date(date);
      const date_2 = new Date(due_date);
      const diffTime = Math.floor(date_2 - date_1);
      return Math.floor(diffTime / (1000 * 60 * 60 * 24));
    },
    convertDate: function (cvt_date) {
      var date = new Date(cvt_date);
      var year = date.getFullYear();
      var month = date.toLocaleString("default", { month: "short" });
      var dt = date.getDate();
      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }
      const cvt_date_formate = month + " " + dt + ", " + year;
      return cvt_date_formate;
    },
    //paid invoice by id set 1
    async paidInvoice(id) {
      await axios.post(`${base_url}/paid_invoice/${id}`).then(() => {});
      this.getInvoice();
    },
    //unpaid multiple set 0
    async invoiceUnpaid(id) {
      await axios
        .post(`${base_url}/unpaid_invoices`, {
          multiple_id: this.formData.checked,
          cat_id: id,
        })
        .then(() => {
          this.formData.checked = [];
        });
      this.getInvoice();
    },
    //per page change
    pageChange() {
      this.filter_page = 1;
      this.page = 1;

      // if (this.is_sort == 1 && this.is_search == 0 && this.is_list == 0) {
      // 	this.searchClient();
      // 	this.searchDate();
      // 	this.sortChange();
      // } else if (this.is_cat == 1 && this.is_search == 0 && this.is_list == 0) {
      // 	this.searchClient();
      // 	this.searchDate();
      // 	this.catChange();
      // } else
      if (this.filter.is_filter == 1) {
        // this.searchClient();
        // this.searchDate();
        this.filterSearch();
      } else {
        // console.log("123");
        this.getAllFilterInvoice();
      }
      //  else if ( this.is_search == 0 && this.is_date == 1 && this.is_cat == 0 && this.is_list == 0 && this.is_sort == 0){				console.log("123");				// this.searchClient();
      // this.searchDate();
      // }else if ( this.is_search == 0 && this.is_client == 1 && this.is_cat == 0 && this.is_list == 0 && this.is_sort == 0){				console.log("123");				// this.searchClient();
      // 	this.searchClient();
      // } else {
      // 	this.searchClient();
      // 	this.searchDate();
      // 	this.getInvoice();
      // }
    },
    async checkAll() {
      this.formData.isCheckAll = !this.formData.isCheckAll;
      this.formData.checked = [];
      if (this.formData.isCheckAll) {
        for (var key in this.invoices) {
          this.formData.checked.push(this.invoices[key].id);
        }
      }
    },
    async updateCheckall() {
      if (this.formData.checked.length == this.invoices.length) {
        this.formData.isCheckAll = true;
      } else {
        this.formData.isCheckAll = false;
      }
    },
    async searchClient() {
      this.getAllFilterInvoice();
      // 	this.is_list = 0;
      // 	this.is_cat = 0;
      // 	this.is_sort = 0;
      // 	this.is_search = 0;
      // 	this.is_date = 0;
      // 	this.is_client = 1;
      // 	this.getAllFilterInvoice();
      // 	const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
      // 	await axios.post(`${base_url}/invoice_client` + params, {
      //   		id: this.selectedClient,
      //   		admin_id: this.$store.state.user.id,
      // 	}).then((res) => {
      //   		this.loading = false;
      //   		this.invoices = res.data.results;
      //   		this.page = res.data.page;
      //   		this.result_length = res.data.length.length;
      // 	});
    },
    async searchDate() {
      this.getAllFilterInvoice();
      // this.is_list = 0;
      // this.is_cat = 0;
      // this.is_sort = 0;
      // this.is_search = 0;
      // this.is_date = 1;
      // this.is_client = 0;
      // const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
      // this.getAllFilterInvoice();
      // await axios.post(`${base_url}/invoice_date` + params, {
      // 	date: this.filter.fromdate,
      // 	due_date: this.filter.duedate,
      // 	admin_id: this.$store.state.user.id,
      // }).then((res) => {
      // 	this.loading = false;
      // 	this.invoices = res.data.results;
      // 	this.page = res.data.page;
      // 	this.result_length = res.data.length.length;
      // 	console.log(this.result_length);
      // });
    },
    async filterCurrency() {
      await axios
        .post(`${base_url}/currency_total`, {
          client_id: this.selectedClient,
          start_date: this.filter.fromdate,
          due_date: this.filter.duedate,
          currency: this.filter.currency,
          admin_id: this.$store.state.user.id,
        })
        .then((res) => {
          this.filter.totalCurrency = res.data[0].currency;
          this.filter.totalPaid = res.data[1].currency;
          this.filter.totalUnpaid = res.data[2].currency;
        });
    },
    async catChange() {
      this.getAllFilterInvoice();
      // this.is_cat = 1;
      // this.is_list = 0;
      // this.is_search = 0;
      // this.is_date = 0;
      // this.is_client = 0;
      // this.getAllFilterInvoice();
      // const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
      // await axios.post(`${base_url}/invoice_cat_search` + params, {
      // 	sort_by: this.formData.isSort,
      // 	cat_id: this.formData.isCat,
      // 	admin_id: this.$store.state.user.id,
      // }).then((res) => {
      // 	this.loading = false;
      // 	this.invoices = res.data.results;
      // 	this.page = res.data.page;
      // 	this.result_length = res.data.length.length;
      // });
    },
    async sortChange() {
      this.getAllFilterInvoice();
      // this.is_sort = 1;
      // this.is_list = 0;
      // this.is_search = 0;
      // this.is_date = 0;
      // this.is_client = 0;
      // this.getAllFilterInvoice();
      // const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
      // await axios.post(`${base_url}/invoice_cat_search` + params, {
      // 	sort_by: this.formData.isSort,
      // 	cat_id: this.formData.isCat,
      // 	admin_id: this.$store.state.user.id,
      // }).then((res) => {
      // 	this.loading = false;
      // 	this.invoices = res.data.results;
      // 	this.page = res.data.page;
      // 	this.result_length = res.data.length.length;
      // });
    },
    async filterSearch() {
      var filter = { name: this.filter.name };
      localStorage.setItem("InvoicesFilter", [JSON.stringify(filter)]);
      this.is_list = 0;
      this.is_cat = 0;
      this.is_sort = 0;
      this.is_date = 0;
      this.is_client = 0;
      const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
      await axios
        .post(`${base_url}/invoices_search` + params, {
          name: this.filter.name,
          admin_id: this.$store.state.user.id,
        })
        .then((res) => {
          this.loading = false;
          this.invoices = res.data.results;
          this.page = res.data.page;
          this.result_length = res.data.length.length;
          this.filter.is_filter = 1;
        });
    },
    async removeSearch() {
      this.filter.name = "";
      this.filter.fromdate = "";
      this.filter.duedate = "";
      (this.selectedClient = ""), (this.filter_page = 1);
      const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
      await axios
        .post(`${base_url}/invoices_search` + params, {
          name: this.filter.name,
          admin_id: this.$store.state.user.id,
        })
        .then((res) => {
          this.loading = false;
          localStorage.removeItem("InvoicesFilter");
          this.invoices = res.data.results;
          this.page = res.data.page;
          this.result_length = res.data.length.length;
          this.filter_page = 1;
          this.filter.is_filter = 0;
        });
    },
    //delete invoice
    async deleteInvoice(id) {
      await axios.post(`${base_url}/remove_invoice/${id}`).then(() => {
        this.$notify({
          title: "<b>Invoice</b> deleted successfully!",
          type: "warn",
        });
      });
      this.getInvoice();
    },
    pageCount(pageNumber) {
      $(window).scrollTop(0);
      // if (this.is_sort == 1) {
      // 	if (!pageNumber) {
      // 		this.filter_page++;
      // 	}
      // 	this.filter_page = pageNumber;
      // 	this.sortChange();
      // } else if (this.is_cat == 1 && this.is_search == 0 && this.is_list == 0) {
      // 	if (!pageNumber) {
      // 		this.filter_page++;
      // 	}
      // 	this.filter_page = pageNumber;
      // 	this.catChange();
      // } else
      if (this.filter.is_filter == 1) {
        if (!pageNumber) {
          this.filter_page++;
        }
        this.filter_page = pageNumber;
        this.filterSearch();
      }
      // else if (this.is_date == 1 && this.is_search == 0 && this.is_list == 0) {
      // 	if (!pageNumber) {
      // 		this.filter_page++;
      // 	}
      // 	this.filter_page = pageNumber;
      // 	this.searchDate();
      // }else if (this.is_client == 1 && this.is_search == 0 && this.is_list == 0) {
      // 	if (!pageNumber) {
      // 		this.filter_page++;
      // 	}
      // 	this.filter_page = pageNumber;
      // 	this.searchClient();
      // }
      else {
        if (!pageNumber) {
          this.page++;
        }
        this.page = pageNumber;
        // this.getInvoice();
        this.getAllFilterInvoice();
      }
    },
    prevPage() {
      $(window).scrollTop(0);
      // if (this.is_sort == 1 && this.is_search == 0 && this.is_list == 0) {
      // 	this.filter_page--;
      // 	this.sortChange();
      // } else if (this.is_cat == 1 && this.is_search == 0 && this.is_list == 0) {
      // 	this.filter_page--;
      // 	this.catChange();
      // } else
      if (this.filter.is_filter == 1) {
        this.filter_page--;
        this.filterSearch();
      }
      //  else if ( this.is_date == 1 && this.is_cat == 0 && this.is_list == 0 && this.is_sort == 0) {
      // 	this.filter_page--;
      // 	this.searchDate();
      // }else if ( this.is_client == 1 && this.is_cat == 0 && this.is_list == 0 && this.is_sort == 0) {
      // 	this.filter_page--;
      // 	this.searchClient();
      // }
      else {
        this.page--;
        // this.getInvoice();
        this.getAllFilterInvoice();
      }
    },
    nextPage() {
      $(window).scrollTop(0);
      // if (this.is_sort == 1 && this.is_search == 0 && this.is_list == 0) {
      // 	this.filter_page++;
      // 	this.sortChange();
      // } else if (this.is_cat == 1 && this.is_search == 0 && this.is_list == 0) {
      // 	this.filter_page++;
      // 	this.catChange();
      // } else
      if (this.filter.is_filter == 1) {
        this.filter_page++;
        this.filterSearch();
      }
      // else if ( this.is_date == 1 && this.is_cat == 0 && this.is_list == 0 && this.is_sort == 0) {
      // 	this.filter_page++;
      // 	this.searchDate();
      // }else if ( this.is_client == 1 && this.is_cat == 0 && this.is_list == 0 && this.is_sort == 0) {
      // 	this.filter_page++;
      // 	this.searchClient();
      // }
      else {
        this.page++;
        // this.getInvoice();
        this.getAllFilterInvoice();
      }
    },
  },
};
</script>
