<template>
  	<div></div>
</template>

<script>
export default {
  	beforeCreate() {
    	if (localStorage.getItem("type") == "admin") {
      		this.$router.push({ name: "dashboard" });
    	} else if (localStorage.getItem("type") == "staff") {
      		this.$router.push({ name: "staff_dashboard" });
    	} else {
      		this.$router.push({ name: "client_projects" });
    	}
  	},
};
</script>

<style></style>
