<template>
  	<dialog open>
	    <slot></slot>
  	</dialog>
</template>

<script>
export default {};
</script>

<style>
dialog {
  	background: none;
  	border: 0;
}
</style>
