<template>
  <div class="panelbox">
    <LeftPannel :image="$store.state.user.image" :cname="$store.state.user.cname" />
    <div :class="type === 'admin' ? 'right-panel' : 'right-panel staff-section-page'
      ">
      <div class="header">
        <CommonHeader v-if="type === 'admin'" title="Attendance Report" :name="$store.state.user.name" />
        <staff-header v-else title="Attendance Report" />
      </div>
      <div class="content-page admin-attendance-page">
        <div class="fullrow">
          <div class="row">
            <div class="col-sm-6"></div>
            <div class="col-sm-6 download-track-button-sec">
              <ul>
                <li>
                  <div class="download-track-button" v-if="filter.is_filter">
                    <a @click="summaryCSV">
                      <img src="../../assets/images/download-arrow.png" alt="Summary" />
                      Attendance Report
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="track-top-filter-form common-white-shadow-background admin-attendance-filter">
            <div class="trach-filter-form-field"></div>
            <div class="trach-filter-form-field">
              <input type="date" name="from" :max="filter.outdate" v-model="filter.indate" />
            </div>
            <div class="trach-filter-form-field">
              <input type="date" name="to" :max="filter.outdate" v-model="filter.outdate" />
            </div>
            <div class="trach-filter-form-field">
              <input type="submit" value="Submit" class="yellow-button" @click="filterSearch" />
            </div>
            <input type="submit" value="Clear" class="yellow-button" v-if="filter.is_filter" @click="removeSearch" />
          </div>
          <div v-if="filter.is_filter">
            Showing {{ result_length }} on {{ pages.length }} page
          </div>
          <div class="col-md-12 page-dropdown">
            <select class="select-dropdown" v-model="pageSize" @change="pageChange">
              <option value="50">50</option>
              <option value="30">30</option>
              <option value="20">20</option>
              <option value="10">10</option>
            </select>
          </div>
          <div style="display: flex; justify-content: flex-end">
            <input type="text" v-model="searchQuery" placeholder="Search by name..." style="
                width: 300px;
                padding: 8px;
                border: 1px solid #ccc;
                border-radius: 4px;
              " v-if="filter.is_filter" />
          </div>

          <div class="row">
            <div class="col-12">
              <div class="fullwidth-table track-table-body attendance-admin-table-body">
                <table>
                  <tr>
                    <th style="padding: 15px 21px 15px 29px">User</th>
                    <th style="padding: 15px 21px 15px 29px">
                      Days Worked
                    </th>
                    <th style="padding: 15px 21px 15px 29px">
                      Leave Taken
                    </th>
                    <th style="padding: 15px 21px 15px 29px">
                      Paid Leave
                    </th>
                    <th style="padding: 15px 21px 15px 29px">
                      Non-paid Leave
                    </th>

                    <th style="padding: 15px 21px 15px 29px">Total Days</th>
                  </tr>
                  <tr v-for="item in filteredItems" :key="item.id">
                    <td>{{ item.name }}</td>
                    <td>{{ item.totalWorkingDays }}</td>
                    <td>{{ item.total_leaves }}</td>
                    <td>{{ item.paid_days }}</td>
                    <td>{{ item.unpaid_days }}</td>
                    <td>{{ item.totalWorkingDays + item.paid_days }}</td>

                  </tr>
                </table>
              </div>
            </div>
            <div class="col-12">
              <div class="admininvoice-pagination">
                <div v-if="!filter.is_filter" class="pagination">
                  <a v-if="page !== 1" @click="prevPage">
                    <img src="../../assets/images/pagi-left.png" alt="&laquo;" />
                  </a>
                  <a :class="pageNumber === page ? 'active' : ''" v-for="pageNumber in pages.slice(page - 1, page + 2)"
                    :key="pageNumber" @click="pageCount(pageNumber)">
                    {{ pageNumber }}
                  </a>
                  <a @click="nextPage" v-if="page < pages.length">
                    <img src="../../assets/images/pagi-right.png" alt="&raquo;" />
                  </a>
                </div>
                <div v-else class="pagination">
                  <a v-if="page !== 1" @click="prevPages">
                    <img src="../../assets/images/pagi-left.png" alt="&laquo;" />
                  </a>
                  <a :class="pageNumber === page ? 'active' : ''" v-for="pageNumber in pages.slice(page - 1, page + 2)"
                    :key="pageNumber" @click="pageCounts(pageNumber)">
                    {{ pageNumber }}
                  </a>
                  <a @click="nextPages" v-if="page < pages.length">
                    <img src="../../assets/images/pagi-right.png" alt="&raquo;" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CommonFooter />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import base_url from "../../base_url";
import CommonFooter from "../../components/CommonFooter.vue";
import CommonHeader from "../../components/admin/CommonHeader.vue";
import LeftPannel from "../../components/admin/LeftPannel.vue";
import StaffHeader from "../../components/user/CommonHeader.vue";
const $ = require("jquery");
window.$ = $;

export default {
  name: "NewAttendanceReport",
  components: {
    LeftPannel,
    CommonHeader,
    CommonFooter,
    StaffHeader,
  },
  data() {
    return {
      type: "",
      items: [],
      names: [],
      searchQuery: "",
      page: 1,
      filter_page: 1,
      result_length: "",
      perPage: 50,
      pageSize: 50,
      pages: [],
      filter: {
        staffid: "",
        indate: new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 1,
          new Date().getDate() + 1
        )
          .toISOString()
          .substr(0, 10),
        outdate: new Date().toISOString().substr(0, 10),
        is_filter: 0,
      },
    };
  },
  created() {
    console.log(this.items, "item log");
    this.type = localStorage.getItem("type");
    axios
      .post(`${base_url}/user`, {
        headers: { token: localStorage.getItem("token") },
        type: localStorage.getItem("type"),
      })
      .then((res) => {
        this.$store.state.user = res.data.user;
        this.getName();
        if (localStorage.getItem("AttendanceFilter")) {
          var staffid = JSON.parse(localStorage.getItem("AttendanceFilter"))[
            "staffid"
          ];
          var indate = JSON.parse(localStorage.getItem("AttendanceFilter"))[
            "indate"
          ];
          var outdate = JSON.parse(localStorage.getItem("AttendanceFilter"))[
            "outdate"
          ];
          this.filter.staffid = staffid;
          this.filter.indate = indate;
          this.filter.outdate = outdate;
          this.filterSearch();
        } else {
          this.showAttendance();
        }
        if (this.$store.state.user.is_business == 1) {
          this.$router.push("/attendancelist");
        } else {
          this.$router.push("/business_setting");
        }
      });
  },
  computed: {
    filteredItems() {
      if (!this.searchQuery) {
        return this.items;
      }
      const query = this.searchQuery.toLowerCase();
      return this.items.filter((item) =>
        item.name.toLowerCase().includes(query)
      );
    },
  },
  watch: {
    items() {
      this.setPages();
    },
  },
  methods: {
    async filterSearch() {
      var filter = {
        staffid: this.filter.staffid,
        indate: this.filter.indate,
        outdate: this.filter.outdate,
      };
      console.log(filter, "filter check");
      localStorage.setItem("AttendanceFilter", [JSON.stringify(filter)]);
      const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
      await axios
        .post(`${base_url}/attendance_report_search` + params, {
          admin_id: this.$store.state.user.id,
          staffid: this.filter.staffid,
          indate: this.filter.indate,
          outdate: this.filter.outdate,
        })
        .then((res) => {
          this.items = res.data.results;
          this.page = res.data.page;
          this.result_length = res.data.length.length;
          this.filter.is_filter = 1;
        });
    },
    async removeSearch() {
      this.filter_page = 1;
      this.filter.staffid = "";
      this.filter.indate = new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        new Date().getDate() + 1
      )
        .toISOString()
        .substr(0, 10);
      this.filter.outdate = new Date().toISOString().substr(0, 10);

      localStorage.removeItem("AttendanceFilter");

      this.filter.is_filter = 0;

      this.items = [];

      this.page = 1;
      this.pages = [];
      this.result_length = 0;
    },

    async summaryCSV() {
      console.log(this.items, "items check");

      const itemsToExport = this.searchQuery ? this.filteredItems : this.items;

      const groupedByCompany = itemsToExport.reduce((acc, item) => {
        if (!acc[item.cname]) {
          acc[item.cname] = [];
        }
        acc[item.cname].push(item);
        return acc;
      }, {});

      let csvContent = [];

      Object.keys(groupedByCompany).forEach((companyName) => {
        csvContent.push(companyName, "");

        let dateRange = `Date Range: ${this.filter.indate} - ${this.filter.outdate}`;
        csvContent.push(dateRange, "");

        let header = [
          "Name",
          "Days Worked",
          "Leave Taken",
          "Paid Leave",
          "Non-paid Leave",
          "Total Days",
        ];
        csvContent.push(header.join(","));

        groupedByCompany[companyName].forEach((item) => {
          let row = [
            item.name,
            item.totalWorkingDays,
            item.total_leaves,
            item.paid_days,
            item.unpaid_days,
            item.totalWorkingDays + item.paid_days,
          ];
          csvContent.push(row.join(","));
        });

        csvContent.push("");
      });

      let finalCsvContent = csvContent.join("\n");

      this.download_summary_csv_file(finalCsvContent);
    },

    download_summary_csv_file(csvContent) {
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `attendance_summary_${this.filter.indate} - ${this.filter.outdate}.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async showAttendance() {
      const params = "?pages=" + this.page + "&size=" + this.pageSize;
      const response = await axios.post(`${base_url}/get_attendance` + params, {
        admin_id: this.$store.state.user.id,
        indate: this.filter.indate,
        outdate: this.filter.outdate,
      });
      this.items = response.data.results;
      this.page = response.data.page;
      this.result_length = response.data.length.length;
    },
    convertDate: function (cvt_date) {
      var date = new Date(cvt_date);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var dt = date.getDate();
      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }
      const cvt_date_formate = dt + "/" + month + "/" + year;
      return cvt_date_formate;
    },
    setPages() {
      this.pages = [];
      this.perPage = this.pageSize;
      let numberOfPages = Math.ceil(this.result_length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    //page number wise display
    pageCount(pageNumber) {
      $(window).scrollTop(0);
      if (!pageNumber) {
        this.page++;
      }
      this.page = pageNumber;
      this.showAttendance();
    },
    //filter page number
    pageCounts(pageNumber) {
      if (!pageNumber) {
        this.filter_page++;
      }
      this.filter_page = pageNumber;
      this.filterSearch();
    },
    //display prev page
    prevPage() {
      $(window).scrollTop(0);
      this.page--;
      this.showAttendance();
    },
    //filter prev page
    prevPages() {
      this.filter_page--;
      this.filterSearch();
    },
    //display next page
    nextPage() {
      $(window).scrollTop(0);
      this.page++;
      this.showAttendance();
    },
    nextPages() {
      this.filter_page++;
      this.filterSearch();
    },
    //per page change
    pageChange() {
      this.filter_page = 1;
      this.page = 1;
      if (this.filter.is_filter == 1) {
        this.filterSearch();
      } else {
        this.showAttendance();
      }
    },
  },
};
</script>

<style></style>
