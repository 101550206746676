<template>
  	<div class="panelbox">
    	<LeftPannel :image="this.$store.state.user.image" :cname="this.$store.state.user.cname" />
    	<div class="right-panel">
      		<div class="header">
        		<CommonHeader title="Dashboard" :name="this.$store.state.user.name" />
      		</div>     
      		<div class="content-page">
        		<div class="row">
          			<div class="col-6"></div>
          			<div class="col-6"></div>
        		</div>
        		<div class="row">
          			<div class="col-6">
            			<h3 style="margin-bottom: -30px;float:left">Project hours summary</h3>
            			<select class="right-dropdown" name="month" v-model="filter.project" @change="get_project($event)">
              				<option v-for="data in filter_value" :value="data.val" :key="data.name">{{ data.name }}</option>
            			</select>
            			<div class="table-box">
              				<table>
                				<tr>
                  					<th>Project Name</th>
                  					<th>Project Time</th>
                				</tr>
                				<tr v-for="item in project_hours" :key="item.id">
                  					<td>{{ item.name }}</td>
                  					<td>{{ item.time }} hour</td>
                				</tr>
              				</table>
            			</div>
          			</div>
          			<div class="col-6">
            			<h3 style="margin-bottom: -30px;float:left">Staff hours summary</h3>
            			<select class="right-dropdown" name="month" v-model="filter.staff" @change="get_attendance($event)">
              				<option v-for="data in filter_value" :value="data.val" :key="data.name">{{ data.name }}</option>
            			</select>
            			<div class="table-box">
              				<table>
                				<tr>
                  					<th>Staff Name</th>
                  					<th>Working Time</th>
                				</tr>
                				<tr v-for="item in staff_hours" :key="item.id">
                  					<td>{{ item.name }}</td>
                  					<td>{{ item.time }} hours</td>
                				</tr>
              				</table>
            			</div>
          			</div>
        		</div>
        		<div class="fullrow">
          			<div class="row">
            			<div class="col-12">
              				<h3>Pending Leaves</h3>
              				<div class="table-box fullwidth-table">
                				<table>
                  					<tr>
                    					<th>Applied Date</th>
                    					<th>Staff Name</th>
                    					<th>First day of absence</th>
                    					<th>Last day of absence</th>
                    					<th>No. of days</th>
                    					<th>Status</th>
                  					</tr>
                  					<tr v-for="item in pending_leaves" :key="item.id">
	                    				<td>{{ this.convertDate(item.created_at) }}</td>
                    					<td>{{ item.name }}</td>
                    					<td>{{ this.convertDate(item.start_date) }}</td>
                    					<td>{{ this.convertDate(item.end_date) }}</td>
                    					<td>{{ item.total_day }} Days</td>
                    					<td>
                      						<div>
                        						<ul style="text-align: center">
                          							<li class="gray-btn" @click="approve(item.id)" style="margin-right: 5px">Approve</li>
                          							<li class="gray-btn" @click="reject(item.id)">Reject</li>
                        						</ul>
                      						</div>
                    					</td>
                  					</tr>
                				</table>
              				</div>
            			</div>
          			</div>
        		</div>
        		<div class="fullrow">
          			<div class="row">
            			<div class="col-12">
              				<h3 style="margin-bottom: -30px">Upcoming Leaves</h3>
              				<select class="right-dropdown" name="month" v-model="filter.upcoming_leave" @change="get_upcomingLeaves($event)">
                				<option v-for="data in upcoming_value" :value="data.val" :key="data.name">{{ data.name }}</option>
              				</select>
              				<div class="table-box fullwidth-table">
                				<table>
                  					<tr>
                    					<th>Applied Date</th>
                    					<th style="width: 240px">Staff Name</th>
                    					<th>First day of absence</th>
                    					<th>Last day of absence</th>
                    					<th>No. of days</th>
                  					</tr>
                  					<tr v-for="item in upcoming_leaves" :key="item.id">
                    					<td>{{ this.convertDate(item.created_at) }}</td>
                    					<td>{{ item.name }}</td>
                    					<td>{{ this.convertDate(item.start_date) }}</td>
                    					<td>{{ this.convertDate(item.end_date) }}</td>
                    					<td>{{ item.total_day }} Days</td>
                  					</tr>
                				</table>
              				</div>
            			</div>
          			</div>
        		</div>
      		</div>
      		<CommonFooter />
    	</div>
  	</div>
</template>

<script>
import axios from "axios";
import LeftPannel from "../components/admin/LeftPannel.vue";
import CommonHeader from "../components/admin/CommonHeader.vue";
import CommonFooter from "../components/CommonFooter.vue";
import base_url from "../base_url.js";

export default {
  	components: {
    	LeftPannel,
    	CommonHeader,
    	CommonFooter,
  	},
  	data() {
	    return {
      		name: "",
      		pending_leaves: [],
      		upcoming_leaves: [],
      		staff_hours: [],
      		project_hours: [],
      		image: "",
      		cname: "",
      		filter_value: [
        		{ val: "this_month", name: "This month" },
        		{ val: "last_30", name: "Last 30 days" },
        		{ val: "last_3", name: "Last 3 months" },
      		],
      		upcoming_value: [
        		{ val: "next_30", name: "Next 30 days" },
        		{ val: "next_60", name: "Next 60 days" },
        		{ val: "next_90", name: "Next 90 days" },
      		],
      		filter: {
        		upcoming_leave: "",
        		staff: "",
        		project: "",
      		},
    	};
  	},
  	created() {
	    axios.post(`${base_url}/user`, {
        	headers: { token: localStorage.getItem("token") },
        	type: localStorage.getItem("type"),
      	}).then((res) => {
        	this.$store.state.user = res.data.user;
        	this.get_project(),
          	this.get_attendance(),
          	this.get_upcomingLeaves(),
          	this.get_pending_leaves();
        	this.filter.staff = "this_month";
        	this.filter.project = "this_month";
        	this.filter.upcoming_leave = "next_30";
        	this.filter.invoice = "last_3";
        	if (this.$store.state.user.is_business == 1) {
          		this.$router.push("/dashboard");
        	} else {
          		this.$router.push("/business_setting");
        	}
      	});
  	},
  	methods: {
    	//pending leaves
    	async get_pending_leaves() {
      		await axios.post(`${base_url}/dashboard_pending_leave`, {
          		admin_id: this.$store.state.user.id,
        	}).then((res) => {
          		this.pending_leaves = res.data;
        	});
    	},
    	//upcoming leaves
    	async get_upcomingLeaves() {
      		const params = "?filter=" + this.filter.upcoming_leave;
      		await axios.post(`${base_url}/dashboard_upcoming_leave` + params, {
          		admin_id: this.$store.state.user.id,
        	}).then((res) => {
          		this.upcoming_leaves = res.data;
        	});
    	},
    	//attendance list
    	async get_attendance() {
      		const params = "?filter=" + this.filter.staff;
      		await axios.post(`${base_url}/dashboard_attendance` + params, {
          		admin_id: this.$store.state.user.id,
        	}).then((res) => {
          		const list = res.data;
          		const size = 4;
          		const items = list.slice(0, size);
          		this.staff_hours = items;
        	});
    	},
    	//project list
    	async get_project() {
      		const params = "?filter=" + this.filter.project;
      		await axios.post(`${base_url}/dashboard_project` + params, {
          		admin_id: this.$store.state.user.id,
        	}).then((res) => {
          		const list = res.data;
          		const size = 4;
          		const items = list.slice(0, size);
          		this.project_hours = items;
        	});
    	},
    	async approve(id) {
      		await axios.post(`${base_url}/approve/${id}`, {
          		admin_email: this.$store.state.user.email,
        	}).then((res) => {
          		if (res.data == "Leave Approve")
            	{
					this.$notify({
              			title: "<b>Leave</b> Approved!",
              			type: "warn",
            		});
            		this.get_pending_leaves();
            		this.get_upcomingLeaves();
          		}
        	});
    	},
    	async reject(id) {
      		await axios.post(`${base_url}/reject/${id}`, {
          		admin_email: this.$store.state.user.email,
        	}).then((res) => {
          		if (res.data == "Leave Reject") {
            		this.$notify({
              			title: "<b>Leave</b> Rejected!",
              			type: "warn",
            		});
            		this.get_pending_leaves();
          		}
        	});
    	},
    	convertDate: function (cvt_date) {
      		var date = new Date(cvt_date);
      		var year = date.getFullYear();
      		var month = date.getMonth() + 1;
      		var dt = date.getDate();
      		if (dt < 10) {
        		dt = "0" + dt;
      		}
      		if (month < 10) {
        		month = "0" + month;
      		}
      		const cvt_date_formate = dt + "/" + month + "/" + year;
      		return cvt_date_formate;
    	},
  	},
};
</script>
