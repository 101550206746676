<template>
  	<div class="panelbox">
    	<LeftPannel :image="this.$store.state.user.image" :cname="this.$store.state.user.cname" />
    	<div class="right-panel">
      		<div class="header">
        		<CommonHeader title="Business Profile Settings" :name="this.$store.state.user.name" />
      		</div>
      
      		<div class="content-page">
        		<div class="fullrow">
          			<div class="row">
            			<div class="col-12">
              				<div class="general-setting-title"><h3>General Settings</h3></div>
            			</div>
            			<div class="col-12">
              				<div class="admin-new-client-form admin-business-profile-setup-page">
                				<div v-if="image == 'NULL' || change_img == 1" class="busines-upload-image">
                  					<input type="file" class="busines-upload-button" name="profile" ref="file" @change="onSelect" />
                				</div>
                				<div class="busines-upload-image" v-else>
                  					<img :src="`${base_url}/static/${this.$store.state.user.image}`" alt="Profile" @click="removeImage" class="busines-upload-button" />
                				</div>
                				<div id="tabs">
                  					<div class="tabContent">
                    					<form @submit.prevent method="post" class="form-box">
                      						<div class="row">
                        						<div class="col-sm-12">
                          							<input type="text" name="name" placeholder="Name" v-model="this.$store.state.user.name" required />
                          							<p style="color: red">{{ errors.name }}</p>
                        						</div>
                        						<div class="col-sm-12">
                          							<input type="text" name="cname" placeholder="Organization Name" v-model="this.$store.state.user.cname" required />
                          							<p style="color: red">{{ errors.cname }}</p>
                        						</div>
                        						<div class="col-sm-6">
                          							<input type="email" name="email" placeholder="Email Address" v-model="this.$store.state.user.email" readonly />
                          							<p style="color: red">{{ errors.email }}</p>
                        						</div>
                        						<div class="col-sm-6">
                          							<input type="text" name="website" placeholder="Website URL" v-model="this.$store.state.user.website" />
                        						</div>
                        						<div class="col-sm-6">
                          							<input type="text" name="phone" placeholder="Phone Number" v-model="this.$store.state.user.phone" required />
                          							<p style="color: red">{{ errors.phone }}</p>
                        						</div>
                        						<div class="col-sm-6">
                          							<input type="number" name="fax" placeholder="Fax Number" v-model="this.$store.state.user.fax" />
                        						</div>
                        						<div class="col-sm-12">
                          							<textarea rows="3" placeholder="Address" name="address" v-model="this.$store.state.user.address" required></textarea>
                          							<p style="color: red">{{ errors.address }}</p>
                        						</div>
                        						<div class="col-sm-3">
                          							<input type="text" placeholder="Postal Code" name="pincode" v-model="this.$store.state.user.pincode" required />
                          							<p style="color: red">{{ errors.pincode }}</p>
                        						</div>
                        						<div class="col-sm-6">
                          							<input type="text" placeholder="City" name="city" v-model="this.$store.state.user.city" required />
                          							<p style="color: red">{{ errors.city }}</p>
                        						</div>
                        						<div class="col-sm-3">
                          							<input type="text" placeholder="State" name="state" v-model="this.$store.state.user.state" required />
                          							<p style="color: red">{{ errors.state }}</p>
                        						</div>
                        						<div class="col-sm-6">
                          							<select name="country" id="country" v-model="this.$store.state.user.country" required>
                            							<option value="" selected>Country</option>
                            							<option v-for="data in countries" :value="data.countryname" :key="data.countryname">{{ data.countryname }}</option>
                          							</select>
                          							<p style="color: red">{{ errors.country }}</p>
                        						</div>
                        						<div class="col-sm-6">
                          							<select name="currency" v-model="this.$store.state.user.currency" required >
                            							<option value="" selected>Currency</option>
                            							<option v-for="data in currencise" :value="data.code" :key="data.code" >{{ data.code }}</option>
                          							</select>
                          							<p style="color: red">{{ errors.currency }}</p>
                        						</div>
                        						<div class="col-sm-12">
                          							<input type="text" name="taxname_one" placeholder="Tax Identification Name 1" v-model="this.$store.state.user.taxname_one" />
                        						</div>
                        						<p></p>
                        						<div class="col-sm-12">
                          							<input type="text" name="taxno_one" placeholder="Tax Identification Number 1" v-model="this.$store.state.user.taxno_one" />
                        						</div>
                        						<p></p>
                        						<div class="col-sm-12">
                          							<input type="text" name="taxname1" placeholder="Tax Identification Name 2" v-model="this.$store.state.user.taxname_two" />
                        						</div>
                        						<p></p>
                        						<div class="col-sm-12">
                          							<input type="text" name="taxno_two" placeholder="Tax Identification Number 2" v-model="this.$store.state.user.taxno_two" />
                        						</div>
                        						<p></p>
                        						<div class="col-sm-12">
                          							<textarea rows="3" placeholder="Notes" name="notes" v-model="this.$store.state.user.notes" ></textarea>
                        						</div>
                        						<p></p>
                        						<div class="col-2">
                          							<input type="submit" class="yellow-button" @click="addIndividual" value="Save" />
                        						</div>
                      						</div>
                    					</form>
                  					</div>
                				</div>
              				</div>
            			</div>
            			<div class="col-12">
              				<div class="busi-tax-rate-setup"><h3>Tax Rate Settings</h3></div>
            			</div>	
            			<div class="col-12">
              				<div class="tax-rate-setup-section common-white-shadow-background">
                				<div class="tax-rate-table">
                  					<div class="tax-rate-table-title">
                    					<div class="row">
                      						<div class="col-sm-8"><h3>Tax Name</h3></div>
                      						<div class="col-sm-3"><h3>Rate (%)</h3></div>
                      						<div class="col-sm-1"></div>
                    					</div>
                  					</div>
                  					<div class="tax-rate-table-data">
                    					<div class="row tax-rate-table-data-single" v-for="(invoice_product, k) in tax_item" :key="k">
                      						<div class="col-sm-8">
                        						<input type="text" v-model="invoice_product.tax_name" />
                        						<input type="hidden" v-model="invoice_product.id" />
                      						</div>
                      						<div class="col-sm-3">
                        						<input class="form-control text-right" type="number" min="0" step=".01" v-model="invoice_product.tax_rate" />
                      						</div>
                      						<div class="col-sm-1" v-if="k >= 0">
                        						<a @click="deleteRow(k, invoice_product, invoice_product.id)">
													<img src="../assets/images/remove.jpg" alt="Add Less" />
												</a>
                      						</div>
                    					</div>
                  					</div>
                  					<div class="newinvoice-data-plus">
                    					<div class="addnew-button">
                      						<a @click="addNewRow"><span>+</span> New Line</a>
                    					</div>
                  					</div>
                  					<div class="tax-rate-save-button">
                    					<input type="submit" @click="addTexDeatils" value="Save" />
                  					</div>
                				</div>
              				</div>
            			</div>
						<div class="col-12">
							  <div class="busi-tax-rate-setup"><h3>Change Password</h3></div>
						</div>	
						<div class="col-12">
              				<div class="common-white-shadow-background">
                				<form class="form-box row" @submit.prevent>
    	                				
    	                				<div class="col-sm-3">
    	                  					<input type="password" name="current_pass" placeholder="Current Password" v-model="formData.current_pass" required />
											  <p style="color: red">
    	                    					{{ errors.current_pass }}
    	                  					</p>
    	                				</div>
    	                				<div class="col-sm-3">
    	                  					<input type="password" name="npassword" placeholder="New Password" v-model="formData.npassword" required />
											  <p style="color: red">
    	                    					{{ errors.npassword }}
    	                  					</p>
    	                				</div>
    	                				<div class="col-sm-3">
    	                  					  <input type="password" name="cpassword" placeholder="Confirm Password" v-model="formData.cpassword" required />
    	                  					<p style="color: red">
    	                    					{{ errors.cpassword }}
    	                  					</p>
    	                				</div>
    	                				<div class="col-sm-3">
											<input type="submit" value="Change Password" class="yellow-button" @click="change_password()" />
    	                				</div>
    	              				</form>
              				</div>
            			</div>
          			</div>
        		</div>
      		</div>
      		<CommonFooter />
    	</div>
  	</div>
</template>

<script>
import LeftPannel from "../components/admin/LeftPannel.vue";
import CommonHeader from "../components/admin/CommonHeader.vue";
import CommonFooter from "../components/CommonFooter.vue";
import axios from "axios";
import base_url from "../base_url.js";

var i = 0;

export default {
	components: {
    	LeftPannel,
    	CommonHeader,
    	CommonFooter,
  	},
  	data() {
	    return {
    		currencise: [],
    		currency: 0,
    		countries: [],
    		country: 0,
    		file: "",
    		change_img: "0",
    		tax_item: [
    		  	{
    		  		tax_rate: "",
    		  		tax_name: "",
    		  		admin_id: "",
    		  		id: "",
    			},
    		],
    		get_tax: "",
    		data: {
    			profile: "",
    		},
			formData: {
      		  	npassword: "",
        		cpassword: "",
        		current_pass: "",
      		},
    		errors: [],
    	};
  	},
  	beforeRouteEnter(to, from, next) {
    	if (localStorage.getItem("type") == "staff") {
      		next({ name: "staff_dashboard" });
    	} else {
      		next();
    	}
  	},
  	created() {
	    this.base_url = base_url;
	    this.getCurrency(), this.getCountry();
	    axios.post(`${base_url}/user`, {
        	headers: { token: localStorage.getItem("token") },
        	type: localStorage.getItem("type"),
      	}).then((res) => {
        	this.$store.state.user = res.data.user;
        	if (!this.$store.state.user.country) {
          		this.$store.state.user.country = "India";
        	}
        	if (!this.$store.state.user.currency) {
          		this.$store.state.user.currency = "INR";
        	}
        	this.tax_item[0]["admin_id"] = this.$store.state.user.id;
        	axios.post(`${base_url}/get_tax_details`, {
            	admin_id: this.$store.state.user.id,
          	}).then((res) => {
            	if (!res.data.length == 0) {
              		this.tax_item = res.data;
            	}
          	});
      	});
  	},
  	methods: {
    	async getCurrency() {
      		const response = await axios.get(`${base_url}/currency`);
      		this.currencise = response.data;
    	},
    	async getCountry() {
      		const response = await axios.get(`${base_url}/country`);
      		this.countries = response.data;
    	},
    	onSelect() {
      		const file = this.$refs.file.files[0];
      		this.file = file;
    	},
    	async addIndividual() {
      		var formData = new FormData();
      		formData.append("file", this.file);
      		this.data.profile = this.file["name"];
      		this.errors = [];
      		if (!this.$store.state.user.name) {
        		this.errors["name"] = "Name is Required";
      		}
      		if (!this.$store.state.user.cname) {
        		this.errors["cname"] = "Organization Name is Required";
      		}
      		if (!this.$store.state.user.email) {
        		this.errors["email"] = "Email is Required";
      		} else if (!this.validEmail(this.$store.state.user.email)) {
        		this.errors["email"] = "Please enter valid email address";
      		}
      		if (this.$store.state.user.phone.length === 0) {
        		this.errors["phone"] = "Phone Number is Required";
      		} else if (!this.validPhone(this.$store.state.user.phone)) {
        		this.errors["phone"] = "Please enter valid Phone number";
      		}
      		if (!this.$store.state.user.address) {
        		this.errors["address"] = "Address is Required";
      		}
      		if (!this.$store.state.user.pincode) {
        		this.errors["pincode"] = "Pincode is Required";
      		}
      		if (!this.$store.state.user.city) {
        		this.errors["city"] = "City is Required";
      		}
      		if (!this.$store.state.user.state) {
        		this.errors["state"] = "State is Required";
      		}
      		if (!this.$store.state.user.country) {
        		this.errors["country"] = "Country is Required";
      		}
      		if (!this.$store.state.user.currency) {
        		this.errors["currency"] = "Currency is Required";
      		}
      		if ( !this.errors["name"] && !this.errors["cname"] && !this.errors["email"] && !this.errors["phone"] && !this.errors["address"] && !this.errors["pincode"] && !this.errors["city"] && !this.errors["state"] && !this.errors["country"] && !this.errors["currency"]) {
        		if (this.$store.state.user.image == "NULL") {
          			await axios.post(`${base_url}/imageupload`, formData).then((res) => {
            			this.data.profile = res.data.file;
            			axios.post(`${base_url}/add_business`, {
                			name: this.$store.state.user.name,
                			website: this.$store.state.user.website,
                			cname: this.$store.state.user.cname,
                			email: this.$store.state.user.email,
                			phone: this.$store.state.user.phone,
                			fax: this.$store.state.user.fax,
                			address: this.$store.state.user.address,
                			pincode: this.$store.state.user.pincode,
                			city: this.$store.state.user.city,
                			state: this.$store.state.user.state,
                			country: this.$store.state.user.country,
                			currency: this.$store.state.user.currency,
                			taxname_one: this.$store.state.user.taxname_one,
                			taxno_one: this.$store.state.user.taxno_one,
                			taxname_two: this.$store.state.user.taxname_two,
                			taxno_two: this.$store.state.user.taxno_two,
                			notes: this.$store.state.user.notes,
                			image: this.data.profile,
              			}).then((res) => {
                			this.errors.push(res.data);
                			if (res.data == "Business updated") {
                  				this.$router.push({ name: "dashboard" });
                			}
              			});
          			});
        		} else {
          			if (this.change_img == "1") {
            			await axios.post(`${base_url}/imageupload`, formData).then((res) => {
                			this.data.profile = res.data.file;
                			this.imagesUp();
              			});
          			} else {
            			axios.post(`${base_url}/add_business`, {
                			name: this.$store.state.user.name,
                			website: this.$store.state.user.website,
                			cname: this.$store.state.user.cname,
                			email: this.$store.state.user.email,
                			phone: this.$store.state.user.phone,
                			fax: this.$store.state.user.fax,
                			address: this.$store.state.user.address,
                			pincode: this.$store.state.user.pincode,
                			city: this.$store.state.user.city,
                			state: this.$store.state.user.state,
                			country: this.$store.state.user.country,
                			currency: this.$store.state.user.currency,
                			taxname_one: this.$store.state.user.taxname_one,
                			taxno_one: this.$store.state.user.taxno_one,
                			taxname_two: this.$store.state.user.taxname_two,
                			taxno_two: this.$store.state.user.taxno_two,
                			notes: this.$store.state.user.notes,
                			image: this.$store.state.user.image,
              			}).then((res) => {
                			this.errors.push(res.data);
                			if (res.data == "Business updated") {
                  				this.$router.push({ name: "dashboard" });
                			}
              			});
          			}
        		}
      		}
    	},
    	async imagesUp() {
      		axios.post(`${base_url}/add_business`, {
          		name: this.$store.state.user.name,
          		website: this.$store.state.user.website,
          		cname: this.$store.state.user.cname,
          		email: this.$store.state.user.email,
          		phone: this.$store.state.user.phone,
          		fax: this.$store.state.user.fax,
          		address: this.$store.state.user.address,
          		pincode: this.$store.state.user.pincode,
          		city: this.$store.state.user.city,
          		state: this.$store.state.user.state,
          		country: this.$store.state.user.country,
          		currency: this.$store.state.user.currency,
          		taxname_one: this.$store.state.user.taxname_one,
          		taxno_one: this.$store.state.usertaxno_one,
          		taxname_two: this.$store.state.user.taxname_two,
          		taxno_two: this.$store.state.user.taxno_two,
          		notes: this.$store.state.user.notes,
          		image: this.data.profile,
        	}).then((res) => {
          		this.errors.push(res.data);
          		if (res.data == "Business updated") {
            		this.$router.push({ name: "dashboard" });
          		}
        	});
    	},
    	addTexDeatils() {
      		if (this.tax_item[i]["tax_name"].length >= 1 || this.tax_item[i]["tax_rate"].length >= 1) {
        		axios.post(`${base_url}/tax_details`, {
            		user_data: this.tax_item,
          		}).then((res) => {
            		this.errors.push(res.data);
            		if (res.data == "Tax Update" || res.data == "Tax Insert") {
              			this.$router.push({ name: "dashboard" });
            		}
          		});
      		}
      		this.$notify({
        		title: "<b>Tax</b> updated!",
        		type: "warn",
      		});
    	},
		//password change
    	async change_password() {
      		this.errors = [];
      		if (!this.formData.current_pass) {
        		this.errors["current_pass"] = "Current Password is Required";
      		}
      		if (!this.formData.npassword) {
        		this.errors["npassword"] = "New Password is Required";
      		} else if (!this.validPass(this.formData.npassword)) {
        		this.errors["npassword"] = "Password has one digit, uppercase, lowercase, speical character and 8 character";
      		}
      		if (!this.formData.cpassword) {
        		this.errors["cpassword"] = "Confirm password is Required";
      		} else if (this.formData.npassword !== this.formData.cpassword) {
        		this.errors["cpassword"] = "Password not match";
      		}
      		if ( !this.errors["current_pass"] && !this.errors["npassword"] && !this.errors["cpassword"]) {
				await axios.post(`${base_url}/admin_change_pass`, {
            		current_pass: this.formData.current_pass,
            		npassword: this.formData.npassword,
            		id: this.$store.state.user.id,
          		}).then((res) => {
            		if (res.data == "invalid Current pass") {
              			this.errors["current_pass"] = "Invalid Current Password";
            		}
            		if (res.data == "Password Change successfully.") {
              			this.$notify({
                			title: "<b>Password</b> changed successfully!",
                			type: "warn",
              			});
              			this.formData.npassword = "";
              			this.formData.cpassword = "";
              			this.formData.current_pass = "";
            		}
          		});
      		}
    	},
    	removeImage() {
      		this.change_img = "1";
      		this.$store.state.user.image = "";
    	},
    	addNewRow() {
      		i++;
      		this.tax_item.push({
        		tax_name: "",
        		tax_rate: "",
        		admin_id: this.$store.state.user.id,
      		});
    	},
    	async deleteRow(index, invoice_product, id) {
      		this.$router.go();
      		var idx = this.tax_item.indexOf(invoice_product);
      		if (idx > -1) {
        		this.tax_item.splice(idx, 1);
      		}
      		idx--;
      		index--;
      		await axios.post(`${base_url}/tax_details/${id}`);
    	},
		validPass: function (password) {
      		var pass =
        		/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
      		return pass.test(password);
    	},
    	validEmail: function (email) {
      		var re =
        		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      		return re.test(email);
    	},
    	validPhone: function (mobile) {
      		var phone = /^(\+\d{1,3}[- ]?)?\d{10}$/;
      		return phone.test(mobile);
    	},
  	},
};
</script>
