<template>
  <div class="panelbox">
    <LeftPannel
      :image="this.$store.state.user.image"
      :cname="this.$store.state.user.cname"
    />
    <div
      :class="
        type == 'admin' ? 'right-panel' : 'right-panel staff-section-page'
      "
    >
      <div class="header">
        <CommonHeader
          v-if="type == 'admin'"
          title="Leave"
          :name="this.$store.state.user.name"
        />
        <staff-header v-else title="Leave"></staff-header>
      </div>
      <div class="loaderbox" v-if="this.loading">
        <img
          style="width: 100px"
          src="../assets/images/Loading-bar.gif"
          alt="&laquo;"
        />
      </div>
      <div class="content-page">
        <div class="row staff-total-timing-list">
          <div class="col-sm-3">
            <div class="staff-total-timing">
              <p>Approved</p>
              <span> {{ this.approve_leave }}</span>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="staff-total-timing">
              <p>Pending</p>
              <span> {{ this.pending_leave }}</span>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="staff-total-timing">
              <p>Rejected</p>
              <span> {{ this.reject_leave }}</span>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="staff-total-timing">
              <p>Canceled</p>
              <span>{{ this.cancle_leave }}</span>
            </div>
          </div>
        </div>
        <div class="track-top-filter-form common-white-shadow-background">
          <div class="trach-filter-form-field">
            <select v-model="filter.user" name="user" id="User">
              <option value="" selected>Staff</option>
              <option v-for="data in names" :value="data.id" :key="data.id">
                {{ data.name }}
              </option>
            </select>
          </div>
          <div class="trach-filter-form-field">
            <input type="date" v-model="filter.start_date" />
          </div>
          <div class="trach-filter-form-field">
            <input
              type="date"
              v-model="filter.end_date"
              :min="filter.start_date"
            />
          </div>
          <div class="trach-filter-form-field">
            <select name="is_status" v-model="filter.is_status">
              <option value="" selected>Leave Type</option>
              <option
                v-for="data in leave_status"
                :value="data.id"
                :key="data.name"
              >
                {{ data.name }}
              </option>
            </select>
          </div>
          <div>
            <input
              type="submit"
              value="Submit"
              :style="{ width: '90px', 'padding-left': '15px' }"
              class="yellow-button"
              @click="filterSearch"
            />
            <input
              type="submit"
              value="Clear"
              :style="{
                width: '90px',
                'margin-left': '5px',
                'padding-left': '23px',
              }"
              class="yellow-button"
              v-if="this.filter.is_filter == 1"
              @click="removeSearch"
            />
          </div>
        </div>
        <div v-if="this.filter.is_filter == 1">
          Showing {{ this.result_length }} on {{ this.pages.length }} page
        </div>
        <div class="col-md-12 page-dropdown">
          <select
            class="select-dropdown"
            v-model="pageSize"
            @change="pageChange($event)"
            style="margin-right: 30px; padding: 8px"
          >
            <option value="50">50</option>
            <option value="30">30</option>
            <option value="20">20</option>
            <option value="10">10</option>
          </select>
          <div class="download-track-button">
            <router-link
              :to="{ name: 'create_admin_leave' }"
              style="padding: 11px 11px"
              ><span>+ </span> Apply Leave Application</router-link
            >
          </div>
        </div>
        <div class="table-box fullwidth-table staff-leave-table-body">
          <table>
            <tr>
              <th>Applied Date</th>
              <th>Staff Name</th>
              <th>From Date</th>
              <th>To Date</th>
              <th>No. of days</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
            <tr v-for="item in leaves" :key="item.id">
              <td>{{ this.convertDate(item.created_at) }}</td>
              <td>{{ item.name }}</td>
              <td>{{ this.convertDate(item.start_date) }}</td>
              <td>{{ this.convertDate(item.end_date) }}</td>
              <td>{{ item.total_day }} Days</td>
              <td>
                <div v-if="item.is_status == 0">
                  <ul style="width: auto; text-align: center">
                    <li
                      style="margin: 5px"
                      class="gray-btn"
                      @click="approve(item.id)"
                    >
                      Approve
                    </li>
                    <li class="gray-btn" @click="reject(item.id)">Reject</li>
                  </ul>
                </div>
                <div v-else-if="item.is_status == 1">
                  <ul style="width: auto; text-align: center">
                    <li style="margin: 5px" class="gray-btn approve">
                      Approve
                    </li>
                    <li class="gray-btn" @click="reject(item.id)">Reject</li>
                  </ul>
                </div>
                <div v-else-if="item.is_status == 2">
                  <ul style="width: auto; text-align: center">
                    <li style="margin: 5px" class="gray-btn">Approve</li>
                    <li class="gray-btn reject">Reject</li>
                  </ul>
                </div>
                <div v-else>
                  <ul style="width: auto; text-align: center">
                    <li class="gray-btn cancel">Canceled</li>
                  </ul>
                </div>
              </td>
              <td>
                <router-link :to="{ name: 'edit_admin_leave', params: { id: item.id } }">
													<img src="../assets/images/pen.png" alt="Edit" />
												</router-link>
              </td>

            </tr>
          </table>
        </div>
        <div class="col-12">
          <div class="admininvoice-pagination">
            <div v-if="filter.is_filter == 0" class="pagination">
              <a v-if="page != 1" @click="prevPage">
                <img src="../assets/images/pagi-left.png" alt="&laquo;" />
              </a>
              <a
                :class="pageNumber == page ? 'active' : ''"
                v-for="pageNumber in pages.slice(page - 1, page + 2)"
                :key="pageNumber"
                @click="pageCount(pageNumber)"
                >{{ pageNumber }}</a
              >
              <a @click="nextPage" v-if="page < pages.length"
                ><img src="../assets/images/pagi-right.png" alt="&raquo;"
              /></a>
            </div>
            <div v-else class="pagination">
              <a v-if="page != 1" @click="prevPages">
                <img src="../assets/images/pagi-left.png" alt="&laquo;" />
              </a>
              <a
                :class="pageNumber == page ? 'active' : ''"
                v-for="pageNumber in pages.slice(page - 1, page + 2)"
                :key="pageNumber"
                @click="pageCounts(pageNumber)"
                >{{ pageNumber }}</a
              >
              <a @click="nextPages" v-if="page < pages.length">
                <img src="../assets/images/pagi-right.png" alt="&raquo;" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <CommonFooter />
    </div>
  </div>
</template>

<script>
import LeftPannel from "../components/admin/LeftPannel.vue";
import CommonHeader from "../components/admin/CommonHeader.vue";
import CommonFooter from "../components/CommonFooter.vue";
import StaffHeader from "../components/user/CommonHeader.vue";
import axios from "axios";
import base_url from "../base_url.js";
const $ = require("jquery");
window.$ = $;

export default {
  components: {
    LeftPannel,
    CommonHeader,
    CommonFooter,
    StaffHeader,
  },
  data() {
    return {
      loading: true,
      type: "",
      leaves: [],
      names: [],
      page: 1,
      filter_page: 1,
      result_length: "",
      perPage: 50,
      pageSize: 50,
      pages: [],
      approve_leave: "",
      pending_leave: "",
      cancle_leave: "",
      reject_leave: "",
      total_leave: "",
      leave_status: [
        { id: 2, name: "Reject" },
        { id: 3, name: "Cancel" },
        { id: 1, name: "Approve" },
        { id: 0, name: "Pending" },
      ],
      filter: {
        is_filter: 0,
        start_date: "",
        end_date: "",
        is_status: "",
        user: "",
      },
      timer: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    if (localStorage.getItem("type") == "staff") {
      if (localStorage.getItem("permission").includes("leave")) {
       
        next();
      } else {
       
        next({ name: "staff_dashboard" });
      }
    } else {
      next();
    }
  },
  created() {
    this.type = localStorage.getItem("type");
    axios
      .post(`${base_url}/user`, {
        headers: { token: localStorage.getItem("token") },
        type: localStorage.getItem("type"),
      })
      .then((res) => {
        this.$store.state.user = res.data.user;
        this.leaveSummary(), this.getName();
        if (localStorage.getItem("LeavesFilter")) {
          var staffid = JSON.parse(localStorage.getItem("LeavesFilter"))[
            "staffid"
          ];
          var is_status = JSON.parse(localStorage.getItem("LeavesFilter"))[
            "is_status"
          ];
          var start_date = JSON.parse(localStorage.getItem("LeavesFilter"))[
            "start_date"
          ];
          var end_date = JSON.parse(localStorage.getItem("LeavesFilter"))[
            "end_date"
          ];
          this.filter.staffid = staffid;
          this.filter.is_status = is_status;
          this.filter.start_date = start_date;
          this.filter.end_date = end_date;
          this.filterSearch();
        } else {
          this.getLeaves();
        }
        if (this.$store.state.user.is_business == 1) {
          this.$router.push("/leave");
        } else {
          this.$router.push("/business_setting");
        }
      });
  },
  watch: {
    leaves() {
      this.setPages();
    },
  },
  methods: {
    //filter
    async filterSearch() {
      var filter = {
        staff_id: this.filter.user,
        is_status: this.filter.is_status,
        start_date: this.filter.start_date,
        end_date: this.filter.end_date,
      };
      localStorage.setItem("LeavesFilter", [JSON.stringify(filter)]);
      this.leaveSummary();
      const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
      await axios
        .post(`${base_url}/all_leave_search` + params, {
          admin_id: this.$store.state.user.id,
          staff_id: this.filter.user,
          is_status: this.filter.is_status,
          start_date: this.filter.start_date,
          end_date: this.filter.end_date,
        })
        .then((res) => {
          this.loading = false;
          this.leaves = res.data.results;
          this.page = res.data.page;
          this.result_length = res.data.length.length;
          this.filter.is_filter = 1;
          if (res.data.results.length == 0) {
            this.pending_leave = "0";
            this.cancle_leave = "0";
            this.reject_leave = "0";
            this.total_leave = "0";
            this.approve_leave = "0";
          } else {
            if (!res.data.results[0]["total_leave"]) {
              this.total_leave = "0";
            } else {
              this.total_leave = res.data.results[0]["total_leave"];
            }
            if (!res.data.results[0]["pending_leave"]) {
              this.pending_leave = "0";
            } else {
              this.pending_leave = res.data.results[0]["pending_leave"];
            }
            if (!res.data.results[0]["cancle_leave"]) {
              this.cancle_leave = "0";
            } else {
              this.cancle_leave = res.data.results[0]["cancle_leave"];
            }
            if (!res.data.results[0]["reject_leave"]) {
              this.reject_leave = "0";
            } else {
              this.reject_leave = res.data.results[0]["reject_leave"];
            }
            if (!res.data.results[0]["approve_leave"]) {
              this.approve_leave = "0";
            } else {
              this.approve_leave = res.data.results[0]["approve_leave"];
            }
          }
        });
    },
    async removeSearch() {
      this.filter_page = 1;
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.filter.is_status = "";
      this.filter.user = "";
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.leaveSummary();
      const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
      await axios
        .post(`${base_url}/all_leave_search` + params, {
          admin_id: this.$store.state.user.id,
          staff_id: this.filter.user,
          is_status: this.filter.is_status,
          start_date: this.filter.start_date,
          end_date: this.filter.end_date,
        })
        .then((res) => {
          this.loading = false;
          localStorage.removeItem("LeavesFilter");
          this.leaves = res.data.results;
          this.page = res.data.page;
          this.result_length = res.data.length.length;
          this.filter_page = 1;
          this.filter.is_filter = 0;
        });
    },
    //leave list
    async getLeaves() {
      const params =
        "?pages=" +
        this.page +
        "&size=" +
        this.pageSize +
        "&type=" +
        localStorage.getItem("type");
      const response = await axios.get(
        `${base_url}/leave/${this.$store.state.user.id}` + params
      );
      this.loading = false;
      this.leaves = response.data.results;
      this.page = response.data.page;
      this.result_length = response.data.length.length;
    },
    pageChange() {
      this.filter_page = 1;
      this.page = 1;
      if (this.filter.is_filter == 1) {
        this.filterSearch();
      } else {
        this.getLeaves();
      }
    },
    // leave summary
    async leaveSummary() {
      await axios
        .post(`${base_url}/all_leavesummary`, {
          admin_id: this.$store.state.user.id,
          staff_id: this.filter.user,
          start_date: this.filter.start_date,
          end_date: this.filter.end_date,
          is_status: this.filter.is_status,
        })
        .then((res) => {
          if (!res.data.total_leave) {
            this.total_leave = "0";
          } else {
            this.total_leave = res.data.total_leave;
          }
          if (!res.data.pending_leave) {
            this.pending_leave = "0";
          } else {
            this.pending_leave = res.data.pending_leave;
          }
          if (!res.data.cancle_leave) {
            this.cancle_leave = "0";
          } else {
            this.cancle_leave = res.data.cancle_leave;
          }
          if (!res.data.reject_leave) {
            this.reject_leave = "0";
          } else {
            this.reject_leave = res.data.reject_leave;
          }
          if (!res.data.approve_leave) {
            this.approve_leave = "0";
          } else {
            this.approve_leave = res.data.approve_leave;
          }
        });
    },
    async approve(id) {
      try {
        const response = await axios.post(`${base_url}/approve/${id}`, {
          admin_name: this.$store.state.user.name,
          image: this.$store.state.user.image,
        });

        if (response.status === 200) {
          this.leaveSummary();
          this.getLeaves();
          this.$notify({
            title: "<b>Leave</b> Approved!",
            type: "warn",
          });
        } else {
          console.log("Unexpected response data:", response.data);
        }
      } catch (error) {
        console.error("Error approving leave:", error);
        this.$notify({
          title: "<b>Error</b> Approving Leave!",
          type: "error",
        });
      }
    },
    async reject(id) {
      try {
        const response = await axios.post(`${base_url}/reject/${id}`, {
          admin_name: this.$store.state.user.name,
          image: this.$store.state.user.image,
        });

        if (response.status === 200) {
          this.leaveSummary();
          this.getLeaves();
          this.$notify({
            title: "<b>Leave</b> Rejected!",
            type: "warn",
          });
        } else {
          console.log("Unexpected response data:", response.data);
        }
      } catch (error) {
        console.error("Error rejecting leave:", error);
        this.$notify({
          title: "<b>Error</b> Rejecting Leave!",
          type: "error",
        });
      }
    },
    async getName() {
      const response = await axios.post(
        `${base_url}/staffsfortrack/${this.$store.state.user.id}`
      );
      this.names = response.data;
    },
    convertDate: function (cvt_date) {
      var date = new Date(cvt_date);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var dt = date.getDate();
      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }
      const cvt_date_formate = dt + "/" + month + "/" + year;
      return cvt_date_formate;
    },
    setPages() {
      this.pages = [];
      this.perPage = this.pageSize;
      let numberOfPages = Math.ceil(this.result_length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    pageCount(pageNumber) {
      $(window).scrollTop(0);
      if (!pageNumber) {
        this.page++;
      }
      this.page = pageNumber;
      this.getLeaves();
    },
    //filter page number
    pageCounts(pageNumber) {
      if (!pageNumber) {
        this.filter_page++;
      }
      this.filter_page = pageNumber;
      this.filterSearch();
    },
    //no filter prev page
    prevPage() {
      $(window).scrollTop(0);
      this.page--;
      this.getLeaves();
    },
    //filter prev page
    prevPages() {
      this.filter_page--;
      this.filterSearch();
    },
    //filter display next page
    nextPage() {
      $(window).scrollTop(0);
      this.page++;
      this.getLeaves();
    },
    nextPages() {
      this.filter_page++;
      this.filterSearch();
    },
  },
};
</script>
