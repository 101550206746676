<template>
  <section class="topheader-section">
    <div class="row">
      <div class="col-md-5"></div>
      <div class="col-md-7">
        <header class="topheader align-items-center">
          <div class="left-logo">
            <img src="../assets/images/logo.png" />
          </div>
          <div class="right-sign">
            <a href="#" v-on:click="signinLink()">Sign In</a>
          </div>
        </header>
      </div>
    </div>
  </section>
  <section class="ragister-login loginpage">
    <div class="row d-flex align-items-center">
      <div class="col-md-5 left-box">
        <div class="inner-slider">
          <div
            id="sliderlogin"
            class="carousel slide"
            data-bs-ride="false"
            data-interval="false"
          >
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#sliderlogin"
                data-bs-slide-to="0"
                class="active"
              ></button>
              <button
                type="button"
                data-bs-target="#sliderlogin"
                data-bs-slide-to="1"
              ></button>
              <button
                type="button"
                data-bs-target="#sliderlogin"
                data-bs-slide-to="2"
              ></button>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <h3>We Help Startup And Digital Agencies Complete Projects</h3>
              </div>
              <div class="carousel-item">
                <h3>We Help Startup And Digital Agencies Complete Projects</h3>
              </div>
              <div class="carousel-item">
                <h3>We Help Startup And Digital Agencies Complete Projects</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-7 right-box">
        <div class="form-body">
          <h1>Password Change Form</h1>
          <form class="form-box" @submit.prevent>
            <input
              type="password"
              name="email"
              v-model="user.npassword"
              placeholder="Enter New Password"
              :class="[errors.email ? 'invalid-class' : '']"
              required
            />
            <p style="color: red">
              {{ errors.password ? errors.password : errors.validate }}
            </p>
            <input
              type="password"
              name="cpassword"
              placeholder="Confirm Password"
              :class="[errors.email ? 'invalid-class' : '']"
              v-model="user.cpassword"
            />
            <p style="color: red">
              {{ errors.cpassword }}
            </p>

            <div class="logindiv">
              <input type="submit" value="Submit" @click="password_change" />
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <section class="bottomcopyright">
    <div class="row">
      <div class="col-md-5">
        <p>
          © Copyright 2021 Sanatan Tech Innovations Pvt Ltd, All Right Reserved
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import base_url from "../base_url.js";

export default {
  data() {
    return {
      user: {
        id: "",
        npassword: "",
        cpassword: "",
        current_pass: "",
      },
      errors: [],
    };
  },
  beforeCreate() {
    var data = window.location.pathname;
    axios.get(`${base_url}/get_token`).then((res) => {
      const alltoken = res.data;
      var array = [];
      for (var i = 0; i < alltoken.length; i++) {
        var find = alltoken[i]["token"];
        array.push(find);
      }
      if (array.includes(data.slice(17)) == true) {
        this.$router.push({ name: "changepassword" });
      } else {
        this.$router.push({ name: "login" });
      }
    });
  },
  methods: {
    async password_change() {
      this.errors = [];
      if (!this.user.npassword) {
        this.errors["password"] = " New Password is Required";
      } else if (!this.validPass(this.user.npassword)) {
        this.errors["validate"] =
          "Password has one digit, uppercase, lowercase, speical character and 8 character";
      }
      if (!this.user.cpassword) {
        this.errors["cpassword"] = "Confirm password is Required";
      } else if (this.user.npassword !== this.user.cpassword) {
        this.errors["validate"] = "Password not match";
      }
      if (this.errors.length == 0) {
        await axios
          .post(`${base_url}/updat-pass`, {
            token: window.location.pathname.slice(17),
            npassword: this.user.npassword,
          })
          .then((res) => {
            this.errors["validate"] = res.data;
            if (res.data == "update success") {
              this.$router.push("/login");
              this.$notify({
                title: "<b>Password</b> changed successfully!",
                type: "warn",
              });
            }
          });
        this.user.npassword = "";
        this.user.cpassword = "";
      }
    },
    validPass: function (password) {
      var pass =
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8}$/;
      return pass.test(password);
    },
    signinLink() {
      this.$router.push("/login");
    },
  },
};
</script>
