<template>
  	<div class="panelbox">
		<LeftPannel :image="this.$store.state.user.image" :cname="this.$store.state.user.cname" />
    	<div :class="type == 'admin' ? 'right-panel' : 'right-panel staff-section-page'">
      		<div class="header">
        		<CommonHeader v-if="type == 'admin'" title="Clients" :name="this.$store.state.user.name" />
        		<staff-header v-else title="Clients"></staff-header>
      		</div>
      		<div class='loaderbox'  v-if="this.clients.length < 0">
              	<img style="width:100px" src="../assets/images/Loading-bar.gif" alt="&laquo;"/>
            </div>
      		<div class="content-page">
        		<div class="fullrow">
          			<div class="row">
            			<div class="col-md-4">
              				<input class="search-leave client-page" type="text" name="search" placeholder="Search Clients" v-model="filter.name" />
            			</div>
            			<div class="col-md-4">
              				<input type="submit" value="Submit" class="yellow-button" @click="filterSearch" />
              				<input style="margin-left: 10px" type="submit" value="Clear" class="yellow-button" v-if="filter.is_filter == 1" @click="removeSearch" />
            			</div>
            			<div class="col-md-4">
              				<div class="addnew-button addnew-client-button">
                				<router-link :to="{ name: 'create_client' }"><span>+</span> New Client</router-link>
              				</div>
            			</div>
            			<div v-if="filter.is_filter == 1">
              				Showing {{ this.result_length }} on {{ this.pages.length }} page
            			</div>
            			<div class="col-md-12 page-dropdown">
              				<select class="select-dropdown" v-model="pageSize" @change="pageChange($event)">
                				<option value="50">50</option>
                				<option value="30">30</option>
                				<option value="20">20</option>
                				<option value="10">10</option>
              				</select>
            			</div>
            			<div class="col-12">
              				<div class="fullwidth-table admin-invoice-page-body admin-newclient-page-body">
                				<table class="admin-new-client-table client-list-new">
                  					<tr>
                    					<th style="text-align: left">Name</th>
                    					<th style="padding-left: 10%; width: 40%">Details</th>
                    					<th></th>
                  					</tr>
                  					<tr v-for="data in clients" :key="data.id">
                    					<td v-if="data.client_type === 'Organization'">
                      						{{ data.organization }} {{ data.name }}({{ data.code }})
                    					</td>
                    					<td v-else>{{ data.name }}({{ data.code }})</td>
                    					<td style="padding-left: 10%; width: 40%">
                      						<ul>
                        						<li>
                          							<img src="../assets/images/envelope.png" alt="Email" /><a>{{ data.email }}</a>
                        						</li>
                        						<li v-if="data.phone">
                          							<img src="../assets/images/telephone.png" alt="Telephone" /><a>{{ data.phone }}</a>
                        						</li>
                      						</ul>
                    					</td>
                    					<td style="width: 20%">
                      						<ul>
                        						<li>
                          							<a @click="deleteClinets(data.id)"><img src="../assets/images/delete.png" alt="Delete" /></a>
                        						</li>
                        						<li>
                          							<router-link :to="{ 'name': 'edit_client', 'params': { id: data.id }}"><img src="../assets/images/pen.png" alt="Edit" /></router-link>
                        						</li>
                      						</ul>
                    					</td>
                  					</tr>
                				</table>
              				</div>
            			</div>
            			<div class="col-12">
              				<div class="admininvoice-pagination">
                				<div v-if="filter.is_filter == 0" class="pagination">
                  					<a v-if="page != 1" @click="prevPage">
                    					<img src="../assets/images/pagi-left.png" alt="&laquo;" />
                  					</a>
                  					<a :class="pageNumber == page ? 'active' : ''" v-for="pageNumber in pages.slice(page - 1, page + 2)" :key="pageNumber" @click="pageCount(pageNumber)">{{ pageNumber }}</a>
                  					<a @click="nextPage" v-if="page < pages.length">
                    					<img src="../assets/images/pagi-right.png" alt="&raquo;" />
                  					</a>
                				</div>
                				<div v-else class="pagination">
                  					<a v-if="page != 1" @click="prevPages">
                    					<img src="../assets/images/pagi-left.png" alt="&laquo;" />
                  					</a>
                  					<a :class="pageNumber == page ? 'active' : ''" v-for="pageNumber in pages.slice(page - 1, page + 2)" :key="pageNumber" @click="pageCounts(pageNumber)" >
                    					{{ pageNumber }}
                  					</a>
                  					<a @click="nextPages" v-if="page < pages.length">
                    					<img src="../assets/images/pagi-right.png" alt="&raquo;" />
                  					</a>
                				</div>
              				</div>
            			</div>
          			</div>
        		</div>
      		</div>
      		<CommonFooter />
    	</div>
  	</div>
</template>

<script>
import LeftPannel from "../components/admin/LeftPannel.vue";
import CommonHeader from "../components/admin/CommonHeader.vue";
import CommonFooter from "../components/CommonFooter.vue";
import StaffHeader from "../components/user/CommonHeader.vue";
import axios from "axios";
import base_url from "../base_url.js";
const $ = require("jquery");
window.$ = $;

export default {
  	components: {
	    LeftPannel,
	    CommonHeader,
	    CommonFooter,
	    StaffHeader,
  	},
  	data() {
    	return {
      		type: "",
      		clients: [],
      		page: 1,
      		filter_page: 1,
      		result_length: "",
      		perPage: 50,
      		pageSize: 50,
      		pages: [],
      		filter: {
        		name: "",
        		is_filter: 0,
      		},
    	};
  	},
  	beforeRouteEnter(to, from, next) {
    	if (localStorage.getItem("type") == "staff") {
      		if (localStorage.getItem("permission").includes("clients")) {
        		next();
      		} else {
        		next({ name: "staff_dashboard" });
      		}
    	} else {
      		next();
    	}
  	},
  	created() {
    	this.type = localStorage.getItem("type");
    	axios.post(`${base_url}/user`, {
        	headers: { token: localStorage.getItem("token") },
        	type: localStorage.getItem("type"),
      	}).then((res) => {
        	this.$store.state.user = res.data.user;

        	if (localStorage.getItem("ClientsFilter")) {
          		var name = JSON.parse(localStorage.getItem("ClientsFilter"))["name"];
          		this.filter.name = name;
          		this.filterSearch();
        	} else {
          		this.getClient();
        	}
        	if (this.$store.state.user.is_business == 1) {
          		this.$router.push("/clients");
        	} else {
          		this.$router.push("/business_setting");
        	}
      	});
  	},
  	watch: {
	    clients() {
      		this.setPages();
    	},
  	},
  	methods: {
    	//filter
    	async filterSearch() {
      		var filter = { name: this.filter.name };
      		localStorage.setItem("ClientsFilter", [JSON.stringify(filter)]);
      		const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
      		await axios.post(`${base_url}/client_search` + params, {
          		name: this.filter.name,
          		admin_id: this.$store.state.user.id,
        	}).then((res) => {
          		this.clients = res.data.results;
          		this.page = res.data.page;
          		this.result_length = res.data.length.length;
          		this.filter.is_filter = 1;
        	});
    	},
    	//remove filter
    	async removeSearch() {
      		this.filter_page = 1;
      		const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
      		this.filter.name = "";
      		await axios.post(`${base_url}/client_search` + params, {
          		name: this.filter.name,
          		admin_id: this.$store.state.user.id,
        	}).then((res) => {
          		localStorage.removeItem("ClientsFilter");
          		this.clients = res.data.results;
          		this.page = res.data.page;
          		this.result_length = res.data.length.length;
          		this.filter_page = 1;
          		this.filter.is_filter = 0;
        	});
    	},
    	//client list
    	async getClient() {
      		const params = "?pages=" + this.page + "&size=" + this.pageSize;
      		const response = await axios.post(`${base_url}/clients` + params, {
      		  	admin_id: this.$store.state.user.id,
      		});
      		this.clients = response.data.results;
      		this.page = response.data.page;
      		this.result_length = response.data.length.length;
    	},
    	//delete client
    	async deleteClinets(id) {
      		await axios.post(`${base_url}/remove_clients/${id}`).then(() => {
        		this.$notify({
          			title: "<b>Client</b> deleted successfully!",
          			type: "warn",
        		});
      		});
      		this.getClient();
    	},
    	setPages() {
      		this.pages = [];
      		this.perPage = this.pageSize;
      		let numberOfPages = Math.ceil(this.result_length / this.perPage);
      		for (let index = 1; index <= numberOfPages; index++) {
        		this.pages.push(index);
      		}
    	},
    	pageCount(pageNumber) {
      		$(window).scrollTop(0);
      		if (!pageNumber) {
        		this.page++;
      		}
      		this.page = pageNumber;
      		this.getClient();
    	},
    	//filter page number
    	pageCounts(pageNumber) {
      		if (!pageNumber) {
	    	    this.filter_page++;
      		}
      		this.filter_page = pageNumber;
      		this.filterSearch();
    	},
    	//no filter prev page
    	prevPage() {
      		$(window).scrollTop(0);
      		this.page--;
      		this.getClient();
    	},
    	//filter prev page
    	prevPages() {
      		this.filter_page--;
      		this.filterSearch();
    	},
    	//filter display next page
    	nextPage() {
      		$(window).scrollTop(0);
      		this.page++;
      		this.getClient();
    	},
    	nextPages() {
      		this.filter_page++;
      		this.filterSearch();
    	},
    	//per page change
    	pageChange() {
      		this.filter_page = 1;
      		this.page = 1;
      		if (this.filter.is_filter == 1) {
        		this.filterSearch();
      		} else {
        		this.getClient();
      		}
    	},
  	},
};
</script>
