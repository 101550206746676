<template>
 	<section class="topheader-section">
    	<div class="row">
      		<div class="col-md-5"></div>
      		<div class="col-md-7">
        		<header class="topheader align-items-center">
          			<div class="left-logo">
            			<img src="../assets/images/logo_new.png" />
          			</div>
          			<div class="right-sign">
            			<a href="#" @click="signinLink">Sign in</a>
          			</div>
        		</header>
      		</div>
    	</div>
  	</section>
  	<section class="ragister-login">
    	<div class="row d-flex align-items-center">
      		<div class="col-md-5 left-box">
        		<div class="inner-slider">
          			<div id="sliderlogin" class="carousel slide" data-bs-ride="false" data-interval="false">
            			<div class="carousel-indicators">
              				<button type="button" data-bs-target="#sliderlogin" data-bs-slide-to="0" class="active"></button>
              				<!-- <button
                				type="button"
                				data-bs-target="#sliderlogin"
                				data-bs-slide-to="1"
              				></button>
              				<button
                				type="button"
                				data-bs-target="#sliderlogin"
                				data-bs-slide-to="2"
              				></button> -->
            			</div>
            			<div class="carousel-inner">
              				<div class="carousel-item active">
                				<h3>Empower your Team with Time Tracking and many other features</h3>
              				</div>
							<!-- <div class="carousel-item">
								<h3>Empower your Team with Time Tracking and many other features</h3>
							</div>
							<div class="carousel-item">
								<h3>Empower your Team with Time Tracking and many other features</h3>
							</div> -->
            			</div>
          			</div>
        		</div>
      		</div>
      		<div class="col-md-7 right-box register-right">
        		<div class="form-body">
          			<h1>Register Now</h1>
          			<form class="form-box" @submit.prevent>
            			<input type="text" name="fullname" placeholder="Full Name*" v-model="formData.fullname" required />
            			<p style="color: red">{{ errors.fullname }}</p>
            			<input type="text" name="cname" placeholder="Company Name*" v-model="formData.cname" required />
            			<p style="color: red">{{ errors.cname }}</p>
            			<input type="tel" name="phone" placeholder="Phone*" v-model="formData.phone" required />
            			<p style="color: red">{{ errors.phone }}</p>
            			<input type="email" name="email" placeholder="Email*" v-model="formData.email" required />
            			<p v-if="errors.title" style="color: red">{{ errors.title }}</p>
            			<p v-else style="color: red">{{ errors.email }}</p>
            			<input type="password" name="password" placeholder="Password*" v-model="formData.password" required />
            			<p style="color: red">{{ errors.password }}</p>
            			<input type="password" name="cpassword" placeholder="Confirm Password*" v-model="formData.cpassword" required />
            			<p style="color: red">{{ errors.cpassword }}</p>
            			<input type="submit" value="Create Account" @click="create_user" />
          			</form>
        		</div>
      		</div>
    	</div>
  	</section>
  	<section class="bottomcopyright">
    	<div class="row">
	      	<div class="col-md-5">
        		<p>© Copyright 2023 Menejar, All Right Reserved</p>
      		</div>
    	</div>
  	</section>
</template>
<script>
import logo from "../images/logo 1.png";
import axios from "axios";
import base_url from "../base_url.js";
const bcrypt = require("bcryptjs");
export default {
  	name: "register",
  	props: ["app"],
  	data() {
	    return {
      		formData: {
        		fullname: "",
        		cname: "",
        		phone: "",
        		email: "",
        		password: "",
        		cpassword: "",
      		},
      		errors: [],
      		logo: logo,
    	};
  	},
  	methods: {
    	async create_user() {
      		var salt = bcrypt.genSaltSync(10);
      		if (!this.formData.fullname) {
        		this.errors["fullname"] = "Fullname is Required";
      		}
      		if (!this.formData.cname) {
        		this.errors["cname"] = "Company name is Required";
      		}
      		if (this.formData.phone.length === 0) {
        		this.errors["phone"] = "phone Number is Required";
      		} else if (!this.validPhone(this.formData.phone)) {
        		this.errors["phone"] = "Please enter valid number.";
      		}
      		if (!this.formData.email) {
        		this.errors["email"] = "Email is Required";
      		} else if (!this.validEmail(this.formData.email)) {
        		this.errors["email"] = "Valid email required.";
      		}
      		if (!this.formData.password) {
        		this.errors["password"] = "Password is Required";
      		} else if (!this.validPass(this.formData.password)) {
				
        		this.errors["password"] = "Password has one digit, uppercase, lowercase, speical character and 8 character";
      		}else if (this.validPass(this.formData.password)) {
				
        		this.errors["password"] = "";
      		}
      		if (!this.formData.cpassword) {
        		this.errors["cpassword"] = "Confirm Password is Required";
      		} else if (this.formData.password !== this.formData.cpassword) {
        		this.errors["cpassword"] = "Password not match";
      		} else if (this.formData.password == this.formData.cpassword) {
        		this.errors["cpassword"] = "";
      		}
      		if (!this.errors["fullname"] && !this.errors["cname"] && !this.errors["phone"] && !this.errors["email"] && !this.errors["password"] && !this.errors["cpassword"]) {
        		await axios.post(`${base_url}/register`, {
            		fullname: this.formData.fullname,
            		cname: this.formData.cname,
            		phone: this.formData.phone,
            		email: this.formData.email,
            		password: bcrypt.hashSync(this.formData.password, salt),
          		}).then((res) => {
            		this.errors["title"] = res.data;
            		if (res.data == "register Success") {
              			this.$router.push({ name: "login" });
            		}
          		});
      		}
    	},
    	signinLink() {
      		this.$router.push("/login");
    	},
    	validEmail: function (email) {
      	var re =
        	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      	return re.test(email);
    	},
    	validPhone: function (mobile) {
      		var phone = /^(\+\d{1,3}[- ]?)?\d{10}$/;
      		return phone.test(mobile);
    	},
    	validPass: function (password) {
      		var pass =
        	/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
      		return pass.test(password);
    	},
  	},
};
</script>
