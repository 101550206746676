<template>
    <div class="panelbox">
        <LeftPannel :image="$store.state.user.image" :cname="$store.state.user.cname" />
        <div :class="type === 'admin' ? 'right-panel' : 'right-panel staff-section-page'
            ">
            <div class="header">
                <CommonHeader v-if="type === 'admin'" title="Invoice Report" :name="$store.state.user.name" />
                <staff-header v-else title="Invoice Report" />
            </div>
            <div class="content-page admin-attendance-page">
                <div class="fullrow">
                    <div class="row">
                        <div class="col-sm-6"></div>
                        <div class="col-sm-6 download-track-button-sec">
                            <ul>
                                <li>
                                    <div class="download-track-button">
                                        <a @click="summaryCSV">
                                            <img src="../../assets/images/download-arrow.png" alt="Summary" />
                                            Invoice Report
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="track-top-filter-form common-white-shadow-background admin-attendance-filter">
                        <div class="trach-filter-form-field"></div>


                        <div class="trach-filter-form-field">
                            <input type="date" name="from" :max="filter.fromdate" v-model="filter.fromdate" />
                        </div>
                        <div class="trach-filter-form-field">
                            <input type="date" name="to" :max="filter.outdate" v-model="filter.duedate" />
                        </div>


                        <div class="trach-filter-form-field">
                            <input type="submit" value="Submit" class="yellow-button" @click="getAllFilterInvoice" />
                        </div>

                        <input type="submit" value="Clear" class="yellow-button" v-if="filter.is_filter === 1"
                            @click="removeSearch" />
                        <select class="right-dropdown" name="iscat" v-model="formData.isCat"
                            @change="catChange($event)">
                            <option value="" selected>Invoice Category</option>
                            <option v-for="data in invoice_cat" :value="data.id" :key="data.name">
                                {{ data.name }}
                            </option>
                        </select>

                    </div>
                    <div v-if="filter.is_filter == 1">
                        Showing {{ result_length }} on {{ pages.length }} page
                    </div>
                    <div class="col-md-12 page-dropdown">
                        <select class="select-dropdown" v-model="pageSize" @change="pageChange">
                            <option value="50">50</option>
                            <option value="30">30</option>
                            <option value="20">20</option>
                            <option value="10">10</option>
                        </select>
                    </div>
                    <div style="display: flex; justify-content: flex-end">
                        <input type="text" v-model="searchQuery" placeholder="Search by name..." style="
                  width: 300px;
                  padding: 8px;
                  border: 1px solid #ccc;
                  border-radius: 4px;
                " v-if="filter.is_filter === 1" />
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="fullwidth-table track-table-body attendance-admin-table-body">
                                <table>
                                    <tr>
                                        <th style="padding: 15px 21px 15px 29px">
                                            No.
                                        </th>

                                        <th style="padding: 15px 21px 15px 29px">
                                            Client Name
                                        </th>
                                        <th style="padding: 15px 21px 15px 29px">
                                            Invoice No
                                        </th>
                                        <th style="padding: 15px 21px 15px 29px">
                                            Currency
                                        </th>
                                        <th style="padding: 15px 21px 15px 29px">
                                            Invoice Amount
                                        </th>
                                        <th style="padding: 15px 21px 15px 29px">
                                            Invoice Date
                                        </th>
                                        <th style="padding: 15px 21px 15px 29px">
                                            Received Amount
                                        </th>

                                        <th style="padding: 15px 21px 15px 29px">Paid Date</th>


                                    </tr>
                                    <tr v-for="(item, index) in invoices" :key="item.id">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ item.organization }}</td>
                                        <td>{{ item.invoice_no }}</td>
                                        <td>{{ item.currency }}</td>
                                        <td>{{ item.total }}</td>
                                        <td>{{ formatDate(item.date) }}</td>
                                        <td>{{
            (item.received_ammount === "0" || item.received_ammount === "" ? "-" :
                item.received_ammount) +
            (item.received_ammount_currency !== null ? " " +
                item.received_ammount_currency : "")
        }}</td>

                                        <td>
                                            {{
                item.date === item.updated_at
                    ? "-"
                    : formatDate(item.updated_at)
            }}
                                        </td>


                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="admininvoice-pagination">
                                <div v-if="!filter.is_filter" class="pagination">
                                    <a v-if="page !== 1" @click="prevPage">
                                        <img src="../../assets/images/pagi-left.png" alt="&laquo;" />
                                    </a>
                                    <a :class="pageNumber === page ? 'active' : ''"
                                        v-for="pageNumber in pages.slice(page - 1, page + 2)" :key="pageNumber"
                                        @click="pageCount(pageNumber)">
                                        {{ pageNumber }}
                                    </a>
                                    <a @click="nextPage" v-if="page < pages.length">
                                        <img src="../../assets/images/pagi-right.png" alt="&raquo;" />
                                    </a>
                                </div>
                                <div v-else class="pagination">
                                    <a v-if="page !== 1" @click="prevPages">
                                        <img src="../../assets/images/pagi-left.png" alt="&laquo;" />
                                    </a>
                                    <a :class="pageNumber === page ? 'active' : ''"
                                        v-for="pageNumber in pages.slice(page - 1, page + 2)" :key="pageNumber"
                                        @click="pageCounts(pageNumber)">
                                        {{ pageNumber }}
                                    </a>
                                    <a @click="nextPages" v-if="page < pages.length">
                                        <img src="../../assets/images/pagi-right.png" alt="&raquo;" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CommonFooter />
        </div>
    </div>
</template>

<script>
import LeftPannel from "../../components/admin/LeftPannel.vue";
import CommonHeader from "../../components/admin/CommonHeader.vue";
import CommonFooter from "../../components/CommonFooter.vue";
import StaffHeader from "../../components/user/CommonHeader.vue";
import axios from "axios";
import base_url from "../../base_url.js";
const $ = require("jquery");
window.$ = $;

export default {
    components: {
        LeftPannel,
        CommonHeader,
        CommonFooter,
        StaffHeader,
    },
    data() {
        return {
            loading: true,
            type: "",
            invoices: [],
            page: 1,
            perPage: 50,
            pageSize: 50,
            filter_page: 1,
            result_length: "",
            pages: [],
            date: new Date().toISOString().substr(0, 10),
            selectedClient: "",
            formData: {
                checked: [],
                isCat: "",
                isSort: "",
                isCheckAll: "false",
            },
            filter: {
                name: "",
                is_filter: 0,
                fromdate: "",
                duedate: "",
                clients: "",
                currency: "",
                currencise: [],
                received_ammount_currency: "",
                received_ammount: "",
                totalCurrency: "",
                totalPaid: "",
                totalUnpaid: "",
            },
            is_sort: 0,
            is_list: 0,
            is_cat: 0,
            is_search: 0,
            is_date: 0,
            is_client: 0,
            invoice_cat: [
                { id: 2, name: "Archive" },
                { id: 3, name: "Cancel" },
                { id: 1, name: "Paid" },
                { id: 0, name: "Unpaid" },
            ],
            invoice_sort: [
                { val: "amt_desc", name: "By Amount Desc" },
                { val: "dt_desc", name: "By Date Desc" },
                { val: "amt_asc", name: "By Amount Asc" },
                { val: "dt_asc", name: "By Date Asc" },
            ],
        };
    },
    beforeRouteEnter(to, from, next) {
        if (localStorage.getItem("type") == "staff") {
            if (localStorage.getItem("permission").includes("invoice_report")) {
                next();
            } else {
                next({ name: "staff_dashboard" });
            }
        } else {
            next();
        }
    },
    created() {
        this.type = localStorage.getItem("type");
        axios
            .post(`${base_url}/user`, {
                headers: { token: localStorage.getItem("token") },
                type: localStorage.getItem("type"),
            })
            .then((res) => {
                this.$store.state.user = res.data.user;
                this.filterClient(), this.getCurrency();
                if (localStorage.getItem("InvoicesFilter")) {
                    var name = JSON.parse(localStorage.getItem("InvoicesFilter"))["name"];
                    this.filter.name = name;
                    this.filterSearch();
                } else {
                    // this.getInvoice();
                    this.getAllFilterInvoice();
                }
                this.formData.isCat = "";
                this.formData.isSort = "";
                if (this.$store.state.user.is_business == 1) {
                    this.$router.push("/invoice_report");
                } else {
                    this.$router.push("/business_setting");
                }
            });
    },
    watch: {
        invoices() {
            this.setPages();
        },
    },
    methods: {
        async getInvoice() {
            this.is_list = 1;
            const params = "?pages=" + this.page + "&size=" + this.pageSize;
            axios
                .get(`${base_url}/get_inovoice/${this.$store.state.user.id}` + params)
                .then((res) => {
                    console.log(res.data.results);
                    this.loading = false;
                    this.invoices = res.data.results;
                    this.page = res.data.page;
                    this.result_length = res.data.length.length;
                });
        },
        async getAllFilterInvoice(isCsv = false) {

            this.is_list = 1;
            const params = "?pages=" + this.page + "&size=" + this.pageSize;
            const body = {
                sort_by: this.formData.isSort,
                cat_id: this.formData.isCat,
                client_id: this.selectedClient,
                start_date: this.filter.fromdate,
                due_date: this.filter.duedate,
                received_ammount_currency: this.filter.received_ammount_currency,
                received_ammount: this.filter.received_ammount,
                admin_id: this.$store.state.user.id,
                is_csv: isCsv ? 1 : 0
            };
            console.log(body);

            axios
                .post(
                    `${base_url}/get_all_filter_inovoice/${this.$store.state.user.id}` + params,
                    body
                )
                .then((res) => {
                    console.log(res.data.results, "invoice res");
                    this.loading = false;
                    this.invoices = res.data.results;
                    this.page = res.data.page;
                    this.result_length = res.data.length.length;
                    if (this.filter.fromdate !== "") {
            this.filter.is_filter = 1;
        } else {
            this.filter.is_filter = 0; 
        }
                })
                .catch((error) => {
                    console.error("Error fetching filtered invoices:", error);
                });
        },

        formatDate(dateString) {
            const date = new Date(dateString);
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const day = date.getDate().toString().padStart(2, '0');
            const month = monthNames[date.getMonth()];
            const year = date.getFullYear();
            return `${month} ${day}, ${year}`;
        },
        async summaryCSV() {
            console.log(this.invoices, "items check");

            // Fetch filtered invoices via API before proceeding with CSV generation
            await this.getAllFilterInvoice(true);

            const itemsToExport = this.searchQuery ? this.filteredItems : this.invoices;

            let csvContent = [];
            let header = [
                "No",
                "Client Name",
                "Invoice No",
                "Currency",
                "Invoice Amount",
                "Invoice Date",
                "Received Amount",
                "Paid Date"
            ];
            csvContent.push(header.join(","));

            itemsToExport.forEach((item, index) => {
                let row = [
                    index + 1,
                    item.organization,
                    item.invoice_no,
                    item.currency,
                    item.total,
                    `"${this.formatDate(item.date).trim()}"`,
                    `"${(item.received_ammount === "0" || item.received_ammount === "" ? "-" : item.received_ammount) +
                    (item.received_ammount_currency === null ? "" : item.received_ammount_currency)}"`,
                    (item.date === item.updated_at && parseFloat(item.received_ammount <= 0)) ? "-" : `"${this.formatDate(item.updated_at).trim()}"`
                ];
                csvContent.push(row.join(","));
            });

            let finalCsvContent = csvContent.join("\n");

            // Trigger CSV file download
            this.download_summary_csv_file(finalCsvContent);
        },






        download_summary_csv_file(csvContent) {
            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
            const url = URL.createObjectURL(blob);

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                `invoice_summary_${new Date().toISOString().slice(0, 10)}.csv`
            );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },


        async getCurrency() {
            const response = await axios.get(`${base_url}/currency`);
            this.filter.currencise = response.data;
        },
        async filterClient() {
            const response = await axios.post(`${base_url}/filterclients`, {
                admin_id: this.$store.state.user.id,
            });
            this.filter.clients = response.data;
        },
        setPages() {
            this.pages = [];
            this.perPage = this.pageSize;
            let numberOfPages = Math.ceil(this.result_length / this.perPage);
            for (let index = 1; index <= numberOfPages; index++) {
                this.pages.push(index);
            }
        },
        dateDifference(date, due_date) {
            const date_1 = new Date(date);
            const date_2 = new Date(due_date);
            const diffTime = Math.floor(date_2 - date_1);
            return Math.floor(diffTime / (1000 * 60 * 60 * 24));
        },
        convertDate: function (cvt_date) {
            var date = new Date(cvt_date);
            var year = date.getFullYear();
            var month = date.toLocaleString("default", { month: "short" });
            var dt = date.getDate();
            if (dt < 10) {
                dt = "0" + dt;
            }
            if (month < 10) {
                month = "0" + month;
            }
            const cvt_date_formate = month + " " + dt + ", " + year;
            return cvt_date_formate;
        },
        //paid invoice by id set 1
        async paidInvoice(id) {
            await axios.post(`${base_url}/paid_invoice/${id}`).then(() => { });
            this.getInvoice();
        },
        //unpaid multiple set 0
        async invoiceUnpaid(id) {
            await axios
                .post(`${base_url}/unpaid_invoices`, {
                    multiple_id: this.formData.checked,
                    cat_id: id,
                })
                .then(() => {
                    this.formData.checked = [];
                });
            this.getInvoice();
        },
        //per page change
        pageChange() {
            this.filter_page = 1;
            this.page = 1;

            // if (this.is_sort == 1 && this.is_search == 0 && this.is_list == 0) {
            // 	this.searchClient();
            // 	this.searchDate();
            // 	this.sortChange();
            // } else if (this.is_cat == 1 && this.is_search == 0 && this.is_list == 0) {
            // 	this.searchClient();
            // 	this.searchDate();
            // 	this.catChange();
            // } else
            if (this.filter.is_filter == 1) {
                // this.searchClient();
                // this.searchDate();
                this.filterSearch();
            } else {
                // console.log("123");
                this.getAllFilterInvoice();
            }
            //  else if ( this.is_search == 0 && this.is_date == 1 && this.is_cat == 0 && this.is_list == 0 && this.is_sort == 0){				console.log("123");				// this.searchClient();
            // this.searchDate();
            // }else if ( this.is_search == 0 && this.is_client == 1 && this.is_cat == 0 && this.is_list == 0 && this.is_sort == 0){				console.log("123");				// this.searchClient();
            // 	this.searchClient();
            // } else {
            // 	this.searchClient();
            // 	this.searchDate();
            // 	this.getInvoice();
            // }
        },
        async checkAll() {
            this.formData.isCheckAll = !this.formData.isCheckAll;
            this.formData.checked = [];
            if (this.formData.isCheckAll) {
                for (var key in this.invoices) {
                    this.formData.checked.push(this.invoices[key].id);
                }
            }
        },
        async updateCheckall() {
            if (this.formData.checked.length == this.invoices.length) {
                this.formData.isCheckAll = true;
            } else {
                this.formData.isCheckAll = false;
            }
        },
        async searchClient() {
            this.getAllFilterInvoice();
            // 	this.is_list = 0;
            // 	this.is_cat = 0;
            // 	this.is_sort = 0;
            // 	this.is_search = 0;
            // 	this.is_date = 0;
            // 	this.is_client = 1;
            // 	this.getAllFilterInvoice();
            // 	const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
            // 	await axios.post(`${base_url}/invoice_client` + params, {
            //   		id: this.selectedClient,
            //   		admin_id: this.$store.state.user.id,
            // 	}).then((res) => {
            //   		this.loading = false;
            //   		this.invoices = res.data.results;
            //   		this.page = res.data.page;
            //   		this.result_length = res.data.length.length;
            // 	});
        },
        async searchDate() {
            this.getAllFilterInvoice();
            // this.is_list = 0;
            // this.is_cat = 0;
            // this.is_sort = 0;
            // this.is_search = 0;
            // this.is_date = 1;
            // this.is_client = 0;
            // const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
            // this.getAllFilterInvoice();
            // await axios.post(`${base_url}/invoice_date` + params, {
            // 	date: this.filter.fromdate,
            // 	due_date: this.filter.duedate,
            // 	admin_id: this.$store.state.user.id,
            // }).then((res) => {
            // 	this.loading = false;
            // 	this.invoices = res.data.results;
            // 	this.page = res.data.page;
            // 	this.result_length = res.data.length.length;
            // 	console.log(this.result_length);
            // });
        },
        async filterCurrency() {
            await axios
                .post(`${base_url}/currency_total`, {
                    client_id: this.selectedClient,
                    start_date: this.filter.fromdate,
                    due_date: this.filter.duedate,
                    currency: this.filter.currency,
                    admin_id: this.$store.state.user.id,
                })
                .then((res) => {
                    this.filter.totalCurrency = res.data[0].currency;
                    this.filter.totalPaid = res.data[1].currency;
                    this.filter.totalUnpaid = res.data[2].currency;
                });
        },
        async catChange() {
            this.getAllFilterInvoice();
            // this.is_cat = 1;
            // this.is_list = 0;
            // this.is_search = 0;
            // this.is_date = 0;
            // this.is_client = 0;
            // this.getAllFilterInvoice();
            // const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
            // await axios.post(`${base_url}/invoice_cat_search` + params, {
            // 	sort_by: this.formData.isSort,
            // 	cat_id: this.formData.isCat,
            // 	admin_id: this.$store.state.user.id,
            // }).then((res) => {
            // 	this.loading = false;
            // 	this.invoices = res.data.results;
            // 	this.page = res.data.page;
            // 	this.result_length = res.data.length.length;
            // });
        },
        async sortChange() {
            this.getAllFilterInvoice();
            // this.is_sort = 1;
            // this.is_list = 0;
            // this.is_search = 0;
            // this.is_date = 0;
            // this.is_client = 0;
            // this.getAllFilterInvoice();
            // const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
            // await axios.post(`${base_url}/invoice_cat_search` + params, {
            // 	sort_by: this.formData.isSort,
            // 	cat_id: this.formData.isCat,
            // 	admin_id: this.$store.state.user.id,
            // }).then((res) => {
            // 	this.loading = false;
            // 	this.invoices = res.data.results;
            // 	this.page = res.data.page;
            // 	this.result_length = res.data.length.length;
            // });
        },
        async filterSearch() {
            var filter = { name: this.filter.name };
            localStorage.setItem("InvoicesFilter", [JSON.stringify(filter)]);
            this.is_list = 0;
            this.is_cat = 0;
            this.is_sort = 0;
            this.is_date = 0;
            this.is_client = 0;
            const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
            await axios
                .post(`${base_url}/invoices_search` + params, {
                    name: this.filter.name,
                    admin_id: this.$store.state.user.id,
                })
                .then((res) => {
                    console.log(res.data.results);
                    this.loading = false;
                    this.invoices = res.data.results;
                    this.page = res.data.page;
                    this.result_length = res.data.length.length;
                    this.filter.is_filter = 1;
                });
        },
        async removeSearch() {
            this.filter.name = "";
            this.filter.fromdate = "";
            this.filter.duedate = "";
            (this.selectedClient = ""), (this.filter_page = 1);
            const params = "?pages=" + this.filter_page + "&size=" + this.pageSize;
            await axios
                .post(`${base_url}/invoices_search` + params, {
                    name: this.filter.name,
                    admin_id: this.$store.state.user.id,
                })
                .then((res) => {
                    this.loading = false;
                    localStorage.removeItem("InvoicesFilter");
                    this.invoices = res.data.results;
                    this.page = res.data.page;
                    this.result_length = res.data.length.length;
                    this.filter_page = 1;
                    this.filter.is_filter = 0;
                });
        },
        //delete invoice
        async deleteInvoice(id) {
            await axios.post(`${base_url}/remove_invoice/${id}`).then(() => {
                this.$notify({
                    title: "<b>Invoice</b> deleted successfully!",
                    type: "warn",
                });
            });
            this.getInvoice();
        },
        pageCount(pageNumber) {
            $(window).scrollTop(0);
            // if (this.is_sort == 1) {
            // 	if (!pageNumber) {
            // 		this.filter_page++;
            // 	}
            // 	this.filter_page = pageNumber;
            // 	this.sortChange();
            // } else if (this.is_cat == 1 && this.is_search == 0 && this.is_list == 0) {
            // 	if (!pageNumber) {
            // 		this.filter_page++;
            // 	}
            // 	this.filter_page = pageNumber;
            // 	this.catChange();
            // } else
            if (this.filter.is_filter == 1) {
                if (!pageNumber) {
                    this.filter_page++;
                }
                this.filter_page = pageNumber;
                this.filterSearch();
            }
            // else if (this.is_date == 1 && this.is_search == 0 && this.is_list == 0) {
            // 	if (!pageNumber) {
            // 		this.filter_page++;
            // 	}
            // 	this.filter_page = pageNumber;
            // 	this.searchDate();
            // }else if (this.is_client == 1 && this.is_search == 0 && this.is_list == 0) {
            // 	if (!pageNumber) {
            // 		this.filter_page++;
            // 	}
            // 	this.filter_page = pageNumber;
            // 	this.searchClient();
            // }
            else {
                if (!pageNumber) {
                    this.page++;
                }
                this.page = pageNumber;
                // this.getInvoice();
                this.getAllFilterInvoice();
            }
        },
        prevPage() {
            $(window).scrollTop(0);
            // if (this.is_sort == 1 && this.is_search == 0 && this.is_list == 0) {
            // 	this.filter_page--;
            // 	this.sortChange();
            // } else if (this.is_cat == 1 && this.is_search == 0 && this.is_list == 0) {
            // 	this.filter_page--;
            // 	this.catChange();
            // } else
            if (this.filter.is_filter == 1) {
                this.filter_page--;
                this.filterSearch();
            }
            //  else if ( this.is_date == 1 && this.is_cat == 0 && this.is_list == 0 && this.is_sort == 0) {
            // 	this.filter_page--;
            // 	this.searchDate();
            // }else if ( this.is_client == 1 && this.is_cat == 0 && this.is_list == 0 && this.is_sort == 0) {
            // 	this.filter_page--;
            // 	this.searchClient();
            // }
            else {
                this.page--;
                // this.getInvoice();
                this.getAllFilterInvoice();
            }
        },
        nextPage() {
            $(window).scrollTop(0);
            // if (this.is_sort == 1 && this.is_search == 0 && this.is_list == 0) {
            // 	this.filter_page++;
            // 	this.sortChange();
            // } else if (this.is_cat == 1 && this.is_search == 0 && this.is_list == 0) {
            // 	this.filter_page++;
            // 	this.catChange();
            // } else
            if (this.filter.is_filter == 1) {
                this.filter_page++;
                this.filterSearch();
            }
            // else if ( this.is_date == 1 && this.is_cat == 0 && this.is_list == 0 && this.is_sort == 0) {
            // 	this.filter_page++;
            // 	this.searchDate();
            // }else if ( this.is_client == 1 && this.is_cat == 0 && this.is_list == 0 && this.is_sort == 0) {
            // 	this.filter_page++;
            // 	this.searchClient();
            // }
            else {
                this.page++;
                // this.getInvoice();
                this.getAllFilterInvoice();
            }
        },
    },
};
</script>

<style></style>